import { observer } from "mobx-react";
import React, { useContext } from "react";
import UserDataStore from "../../store/UserDataStore";
import ToastContext from "../../components/toast/ToastContext";
import { UserDataToasts } from "../../toastMessages/userDataToastMessages";
import { UserDataType } from "../../api/firebase/types/userData.types";
import FirstNameFormField from "./formFields/FirstNameFormField";
import CompanyFormField from "./formFields/CompanyFormField";
import { FormControlProps } from "../../components/validation/FormControlled";
import BirthdateFormField from "./formFields/BirthdateFormField";
import LastNameFormField from "./formFields/LastNameFormField";

export type UserDataProps = {
  useFormMethods: FormControlProps;
  userData?: UserDataType;
  editable?: boolean;
  onSubmit?: () => void;
  onError?: () => void;
  showLoader?: () => void;
  formId?: string;
};

const UserDataForm: React.FC<UserDataProps> = ({
  userData,
  editable = true,
  onSubmit,
  onError,
  showLoader,
  useFormMethods,
  formId = "userdata-form",
}) => {
  const showToast = useContext(ToastContext);

  const onhandleSubmit = async (data: any) => {
    showLoader && showLoader();
    let userData: UserDataType = {
      ...data,
      birthDate: data.birthDate,
    };
    try {
      await UserDataStore.updateUserDataDoc(userData);
      showToast(UserDataToasts.saveUserDataSuccess);
      onSubmit && onSubmit();
    } catch (e) {
      showToast(UserDataToasts.saveUserDataError);
      onError && onError();
    }
  };

  return (
    <form
      id={formId}
      autoComplete="off"
      onSubmit={useFormMethods.handleSubmit(onhandleSubmit)}
    >
      <div className="p-fluid">
        <FirstNameFormField
          value={userData?.firstName}
          id="firstName"
          disabled={!editable}
          {...useFormMethods}
        />
        <LastNameFormField
          value={userData?.lastName}
          id="lastName"
          disabled={!editable}
          {...useFormMethods}
        />
        <CompanyFormField
          value={userData?.company}
          id="company"
          disabled={!editable}
          {...useFormMethods}
        />
        <label htmlFor="birthDate">
          Geburtsdatum <small>(nur bei privater Nutzung)</small>
        </label>
        <BirthdateFormField
          value={userData?.birthDate?.toDate()}
          id="birthDate"
          disabled={!editable}
          {...useFormMethods}
        />
      </div>
    </form>
  );
};

export default observer(UserDataForm);
