import React, { useState } from "react";
import { Badge, BadgeProps } from "primereact/badge";
import { Button, ButtonProps } from "primereact/button";
import classnames from "classnames";

type BadgeSelectButtonProps = Omit<ButtonProps, "onClick"> & {
  badgeProps?: Omit<BadgeProps, "value">;
  onClick: () => void;
  activeButtonColor?: string;
  isActive: boolean;
  numOfElements: number;
};

const BadgeSelectButton: React.FC<BadgeSelectButtonProps> = (props) => {
  const {
    activeButtonColor = "p-button-primary",
    badgeProps = {
      severity: "danger",
    },
    onClick,
    className,
    ...otherButtonProps
  } = props;
  const [currentValue, setCurrentValue] = useState<number>(props.numOfElements);

  const handleClick = () => {
    setCurrentValue(props.numOfElements);
    props.onClick();
  };

  return (
    <Button
      className={classnames(className, "p-jc-center", "p-px-4", {
        [activeButtonColor]: props.isActive,
        "p-button-secondary": !props.isActive,
      })}
      label="Testmeldungen"
      onClick={handleClick}
      {...otherButtonProps}
    >
      {!props.isActive && props.numOfElements !== currentValue && (
        <Badge value={props.numOfElements - currentValue} {...badgeProps} />
      )}
    </Button>
  );
};

export default BadgeSelectButton;
