import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useContext, useEffect, useRef, useState } from "react";
import ToastContext from "../../components/toast/ToastContext";
import { UserContext } from "../../store/UserProvider";
import { auth } from "../../api/firebase";
import { AuthToasts } from "../../toastMessages/authToastMessages";
import { FirebaseError } from "firebase/app";
import {
  signInWithEmailAndPassword,
  updatePassword,
  User,
} from "firebase/auth";

const UpdatePasswordButton: React.FC<{ isEditing: boolean }> = ({
  isEditing,
}) => {
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const user = useContext(UserContext);
  const showToast = useContext(ToastContext);

  const opRef = useRef<OverlayPanel>(null);

  const updateUserPassword = async () => {
    try {
      const user = await signIn();
      await setNewPassword(user!);
    } catch (e) {
      console.log(e);
    }
    opRef.current?.hide();
  };

  const signIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        user!.email!,
        oldPassword
      );
      return userCredential.user;
    } catch (error: any) {
      if (error.code === "auth/wrong-password")
        showToast(AuthToasts.wrongPasswordError);
      else if (error.code === "auth/invalid-email")
        showToast(AuthToasts.invalidMailError);
      else if (error.code === "auth/user-not-found")
        showToast(AuthToasts.userNotFoundError);
      else if (error.code === "auth/user-disabled")
        showToast(AuthToasts.userDisabledError);
    }
  };

  const setNewPassword = async (user: User) => {
    await updatePassword(user, password)
      .then(() => {
        showToast(AuthToasts.passwordUpdateSuccess);
        return user;
      })
      .catch((error: FirebaseError) => {
        if (error.code === "auth/weak-password")
          showToast(AuthToasts.weakPasswordError);
      });
    return undefined;
  };

  useEffect(() => {
    if (opRef.current) opRef.current.hide();
  }, []);

  return (
    <div className="p-d-flex p-align-center">
      <p className="userdata-p">Passwort:</p>
      <b className="p-ml-3">*******</b>
      {isEditing && (
        <Button
          type="button"
          id="update-email-btn"
          className="p-button-text"
          label="Ändern"
          onClick={(e) => opRef.current && opRef.current.toggle(e)}
        />
      )}
      <OverlayPanel ref={opRef} showCloseIcon dismissable>
        <h3>Passwort aktualisieren</h3>
        <InputText
          placeholder="Altes Passwort"
          type="password"
          onChange={(e) => setOldPassword(e.currentTarget.value)}
        />
        <InputText
          className="p-ml-2"
          placeholder="Neues Passwort"
          type="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <Button
          type="button"
          className="p-button-primary p-ml-2"
          icon="pi pi-reply"
          iconPos="right"
          onClick={updateUserPassword}
        />
      </OverlayPanel>
    </div>
  );
};

export default UpdatePasswordButton;
