import classNames from "classnames";
import { observer } from "mobx-react";
import moment from "moment";
import { Card } from "primereact/card";
import { useContext } from "react";
import {
  IncidentStatus,
  IncidentType,
} from "../../api/firebase/types/incident.types";
import { UserDataType } from "../../api/firebase/types/userData.types";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import { UserContext } from "../../store/UserProvider";

type HomeViewInfoCardProps = {
  incidents?: IncidentType[];
  userData?: UserDataType;
};

const HomeViewInfoCard: React.FC<HomeViewInfoCardProps> = (props) => {
  const user = useContext(UserContext);
  const [width] = useMediaQuery();

  const latestIncident =
    props.incidents?.length === 0
      ? undefined
      : props.incidents?.reduce((latestIncident, incident) => {
          return latestIncident.created!.seconds > incident.created!.seconds
            ? latestIncident
            : incident;
        });

  const failedIncidents = props.incidents?.filter((incident) => {
    if (!incident.psap_reporting_status) return false;
    const errorStatuses: string[] = [
      IncidentStatus.FAILED_PERMANENTLY,
      IncidentStatus.FAILED_TEMPORARILY,
      IncidentStatus.IHBACKEND_CONNECTION_ERROR,
    ];
    return errorStatuses.includes(incident.psap_reporting_status);
  });

  const infoCardElems: Record<string, any> = {
    "Letzter Login: ": `${moment(user?.metadata.lastSignInTime).format(
      "DD.MM.yyyy HH:mm"
    )} Uhr`,
    "Letzter Alarm: ": `${
      latestIncident === undefined
        ? "Keine Alarme vorhanden"
        : moment(latestIncident?.created?.toDate()).format("DD.MM.yyyy HH:mm") +
          " Uhr"
    }`,
    "Alarme gesamt: ": props.incidents?.length,
  };

  return (
    <Card
      className={classNames("homeview-info-card p-col p-mb-2", {
        "p-mr-2": width >= screenSize.phone,
      })}
    >
      <h2>Willkommen, {props.userData?.firstName}!</h2>
      <table>
        <tbody>
          {Object.entries(infoCardElems).map((infoCardElem) => (
            <tr>
              <td>
                <b>{infoCardElem[0]}</b>
              </td>
              <td className="homeview-info-value">{infoCardElem[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default observer(HomeViewInfoCard);
