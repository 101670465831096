import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DeviceType } from "../../../api/firebase/types/devices.types";
import { ContactType } from "../../../api/firebase/types/emergencyContacts.types";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import ToastContext from "../../../components/toast/ToastContext";
import ContactStore from "../../../store/ContactStore";
import DeviceStore from "../../../store/DeviceStore";
import { DeviceToasts } from "../../../toastMessages/deviceToastMessages";
import AddContactDialog from "../../contacts/AddContactDialog";
import { fieldIds } from "./DeviceSettings";
import ContactsFormField from "./formFields/ContactsFormField";
import EmergencyOptionsFormField from "./formFields/EmergencyOptionsFormField";
import LocationFormField from "./formFields/LocationFormField";
import SilentMessageFormField from "./formFields/SilentMessageFormField";
import UserDataStore from "../../../store/UserDataStore";

export type DeviceDialogType = {
  serialNumber?: string;
  device?: DeviceType;
};

const DeviceSettingsModal: React.FC<DeviceDialogType> = (props) => {
  const contacts = ContactStore.contacts;
  const emergencyOptions = UserDataStore.currentUser?.emergencyOptions;
  const showEmergencyOptions = emergencyOptions?.length !== 1;

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [addContactDialogVisible, setAddContactDialogVisible] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);

  const showToast = useContext(ToastContext);

  const useFormMethods = useForm();
  const onCancel = () => {
    useFormMethods.reset();
    DeviceStore.resetCurrentDevice();
  };

  const onSave = (data: any) => {
    setShowLoader(true);
    const updatedDevice: Partial<DeviceType> = {
      ...data,
      contactIdsToNotify: data.contactIdsToNotify
        ? data.contactIdsToNotify.map((contact: ContactType) => contact.id)
        : [],
      incidentDetails: data.incidentDetails || "",
    };
    if (emergencyOptions?.length === 1)
      updatedDevice.emergencyCall = emergencyOptions[0];

    DeviceStore.updateCurrentDevice(updatedDevice)
      .then(() => {
        showToast(DeviceToasts.deviceSettingsSaved);
      })
      .catch(() => {
        showToast(DeviceToasts.deviceSettingsSaveError);
      })
      .finally(() => {
        setShowLoader(false);
        DeviceStore.resetCurrentDevice();
      });
  };
  const dialogFooter = () => {
    return (
      <>
        <Button
          label="Speichern"
          onClick={useFormMethods.handleSubmit(onSave)}
        />
        <Button
          label="Abbrechen"
          className="p-button-secondary p-button-text"
          onClick={handleCancelClick}
        />
      </>
    );
  };

  const handleCancelClick = () => {
    if (props.device?.emergencyCall) {
      onCancel();
    } else {
      setCancelDialogVisible(true);
    }
  };

  const handleDeleteDeviceClicked = async () => {
    setShowLoader(true);
    const { deviceDeleted, deviceDeletionError } = DeviceToasts;
    const deletionSucceeded = await DeviceStore.deleteCurrentDevice();
    showToast(deletionSucceeded ? deviceDeleted : deviceDeletionError);
    setShowLoader(false);
  };

  const header = (
    <>
      <Button
        className={
          props.device?.deviceTestMode
            ? "p-button-danger"
            : "p-button-text p-button-warning"
        }
        key="test-mode-button"
        style={{ height: 40 }}
        label={`Testmodus ${
          props.device?.deviceTestMode ? "deaktivieren" : "aktivieren"
        }`}
        onClick={() =>
          DeviceStore.updateCurrentDevice({
            deviceTestMode: !props.device?.deviceTestMode,
          })
        }
      />
      <Button
        key="delete-button"
        icon="pi pi-trash"
        className="p-button-text p-button-danger"
        style={{ height: 40 }}
        onClick={() => setShowDeleteDialog(true)}
      />
    </>
  );
  return (
    <Dialog
      visible={props.serialNumber != undefined}
      header={
        <Toolbar
          left={() => <b>{props.device?.name}</b>}
          right={() => header}
        />
      }
      modal
      className="p-fluid device-detail p-col"
      footer={dialogFooter}
      onHide={handleCancelClick}
    >
      {showLoader && <ProgressBar mode="indeterminate" />}
      <div className="device-setting-section">
        <div className="device-setting-group">
          <div className="p-ai-center">
            <b className="userdata-p">Seriennummer:</b>
            <b className="p-ml-3">{props.serialNumber}</b>
          </div>

          <LocationFormField
            id={fieldIds.location}
            value={props.device?.location || undefined}
            {...useFormMethods}
          />
        </div>
      </div>
      <Divider />
      <div className="device-setting-section">
        {showEmergencyOptions && (
          <div className="device-setting-group">
            <b>Notrufleitstelle</b>
            <p>
              Wählen Sie aus, an welche Enpfänger Ihr Notruf adressiert werden
              soll.
            </p>
            <EmergencyOptionsFormField
              id={fieldIds.emergencyCallOption}
              value={props.device?.emergencyCall || ""}
              emergencyOptions={emergencyOptions}
              {...useFormMethods}
            />
          </div>
        )}
        <div className="device-setting-group">
          <b>Notfallkontakte</b>
          <p>
            Sie können Ihre Notfallkontakte im entsprechenden Reiter (siehe
            oben) verwalten. Sofern eine offizielle Leitstelle als Emfpänger
            ausgewählt wurde, werden die Notfallkontakte ebenfalls alarmiert.
          </p>
          <div className="p-grid p-d-flex p-d-row p-align-center">
            <ContactsFormField
              id={fieldIds.contactsToNotify}
              contacts={contacts}
              value={
                ContactStore.getContactsById(
                  props.device?.contactIdsToNotify
                ) || []
              }
              {...useFormMethods}
            />
            <Button
              type="button"
              id="update-address-btn"
              className="setting-entry p-button-text "
              icon="pi pi-plus"
              label="Kontakt"
              onClick={() => setAddContactDialogVisible(true)}
            />
          </div>
        </div>
        <div className="device-setting-group">
          <b>Zusätzliche Informationen</b>
          <p>
            Beschreiben Sie hier das Szenario, für welchen Sie den Button nutzen
            möchten, z.B. "Überfall" oder "Medizinischer Notfall".
          </p>
          <SilentMessageFormField
            id={fieldIds.incidentDetails}
            value={props.device?.incidentDetails || ""}
            {...useFormMethods}
          />
        </div>
        <AddContactDialog
          isVisible={addContactDialogVisible}
          closeModal={() => setAddContactDialogVisible(false)}
          showLoader={setShowLoader}
        />
      </div>
      <ConfirmDialog
        header="Konfiguration abbrechen?"
        content="Ohne Konfiguration ist Ihr Gerät nicht einsatzbereit. Möchten Sie wirklich abbrechen?"
        isVisible={cancelDialogVisible}
        onHide={() => setCancelDialogVisible(false)}
        onClickYes={onCancel}
      />
      <ConfirmDialog
        header="Gerät entfernen?"
        content="Willst du das Gerät wirklich entfernen?"
        isVisible={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
        onClickYes={handleDeleteDeviceClicked}
      />
    </Dialog>
  );
};

export default observer(DeviceSettingsModal);
