import React, { useState } from "react";
import DiseaseForm, { DiseaseFormProps } from "../../userData/DiseaseForm";
import { Toolbar } from "primereact/toolbar";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { useForm } from "react-hook-form";

const DiseaseStep: React.FC<Omit<DiseaseFormProps, "useFormMethods">> = ({
  diseases,
  hasHearingImpairment,
  onSubmit,
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const useFormMethods = useForm();

  const onFinishEditing = () => {
    setShowLoader(false);
    onSubmit && onSubmit();
  };

  return (
    <Card>
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar
        left={() => <h2>Allgemeine Informationen für Rettungskräfte</h2>}
      />
      <DiseaseForm
        useFormMethods={useFormMethods}
        diseases={diseases}
        hasHearingImpairment={hasHearingImpairment}
        formId="onboarding-form"
        onSubmit={onFinishEditing}
        onError={onFinishEditing}
        showLoader={() => setShowLoader(true)}
      />
    </Card>
  );
};

export default DiseaseStep;
