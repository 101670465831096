import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import InfoInput from "../../../components/input/InfoInput";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps & {
    disabled: boolean;
  };

export default function LastNameFormField(props: ControlledIconFloatInputProps) {
  return (
    <FormControlled
      {...props}
      rules={{
        minLength: 3,
        required: true,
      }}
      errorMessages={{
        minLength: "Der Nachname muss aus mindestens 3 Buchstaben bestehen",
        required: "Bitte geben Sie Ihren Nachnamen an",
      }}
    >
      {(renderProps) => (
        <InfoInput infotext="Nachname" disabled={props.disabled} {...renderProps} />
      )}
    </FormControlled>
  );
}
