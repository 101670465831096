import React from "react";
import classNames from "classnames";

interface CardProps {
  header?: JSX.Element;
  footer?: JSX.Element;
  title: string;
  subTitle?: string;
  style?: object;
  className?: string;
}

const Card: React.FC<CardProps> = (props) => {
  let className = classNames("p-card p-component", props.className);

  const renderBody = () => {
    return (
      <div className="p-card-body">
        {props.title && <div className="p-card-title">{props.title}</div>}
        {props.subTitle && (
          <div className="p-card-subtitle">{props.subTitle}</div>
        )}
        {props.children && (
          <div className="p-card-content"> {props.children} </div>
        )}
        {props.footer && <div className="p-card-footer"> {props.footer}</div>}
      </div>
    );
  };

  return (
    <div className={className} style={props.style}>
      {props.header && <div className="p-card-header">{props.header}</div>}
      {renderBody()}
    </div>
  );
};

export default Card;
