import classNames from "classnames";
import { observer } from "mobx-react";
import { MultiSelect } from "primereact/multiselect";
import React from "react";
import { ContactType } from "../../../../api/firebase/types/emergencyContacts.types";
import { EmergencyCall } from "../../../../api/firebase/types/incident.types";
import contactListTemplate, {
  selectedContactTemplate,
} from "../../../../components/templates/ContactListTemplate";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../../components/validation/FormControlled";
import { fieldIds } from "./../DeviceSettings";

type ContactsFormFieldProps = FormBehaviourProps<ContactType[]> &
  FormControlProps & { contacts?: ContactType[] };

const ContactsFormField: React.FC<ContactsFormFieldProps> = (props) => {
  return (
    <FormControlled
      {...props}
      rules={{
        validate: {
          notEmpty: (value) => {
            return (
              (value && value.length > 0) ||
              props.getValues(fieldIds.emergencyCallOption) !==
                EmergencyCall.CONTACT_REPORTING_ONLY
            );
          },
        },
      }}
      errorMessages={{
        notEmpty: "Bitte geben Sie mindestens einen Notfallkontakt an",
      }}
    >
      {({ onChange, ...renderProps }) => (
        <MultiSelect
          className={classNames("setting-entry", {
            "p-invalid": renderProps.error,
          })}
          itemTemplate={contactListTemplate}
          selectedItemTemplate={selectedContactTemplate}
          options={props.contacts}
          optionLabel="firstName"
          onChange={(e: any) => onChange && onChange(e.value)}
          {...renderProps}
        />
      )}
    </FormControlled>
  );
};
export default observer(ContactsFormField);
