import { ToastMessageType } from "../components/toast/ToastContext";

const ToastMessages: { [key: string]: ToastMessageType } = {
  verificationCodeSent: {
    severity: "success",
    summary: "Verifizierungscode gesendet",
    detail: "Es wurde ein Verifizierungscode an die angegebene Nummer gesendet",
  },
  tooManyRequests: {
    severity: "error",
    summary: "Verifizierungscode nicht gesendet",
    detail:
      "Es wurden zu viele Anfragen gesendet. Bitte versuchen Sie es später erneut",
  },
  verificationCodeSendError: {
    severity: "error",
    summary: "Verifizierungscode nicht gesendet",
    detail:
      "Es konnte kein Verifizierungscode an die angegebene Nummer gesendet werden",
  },
  verificationSuccessfull: {
    severity: "success",
    summary: "Verifizierung erfolgreich",
    detail: "Die Verifizierung wurde erfolgreich durchgeführt",
  },
  wrongVerificationCode: {
    severity: "error",
    summary: "Verifizierung fehlgeschlagen",
    detail: "Der eingegebene Verifizierungscode ist inkorrekt",
  },
  phoneNumberBlocked: {
    severity: "error",
    summary: "Verifizierung fehlgeschlagen",
    detail: "Die angegebene Telefonnummer wurde blockiert",
  },
  verificationFailed: {
    severity: "error",
    summary: "Verifizierung fehlgeschlagen",
    detail: "Bei der Verifizierung ist ein Fehler aufgetreten",
  },
  phoneAlreadyInUse: {
    severity: "error",
    summary: "Verifizierung fehlgeschlagen",
    detail: "Die Telefonnummer ist bereits einem anderen Nutzer zugeordnet",
  },
};

export default ToastMessages;
