import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import IconFloatInput from "../../../components/input/IconFloatInput";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps;

export default function PasswordRepeatFormField(
  props: ControlledIconFloatInputProps
) {
  return (
    <FormControlled
      {...props}
      rules={{
        required: true,
        validate: {
          equalToPassword: (value) => value === props.getValues("password"),
        },
      }}
      errorMessages={{
        equalToPassword: "Die Passwörter stimmen nicht überein",
        required: "Bitte wiederholen Sie das eingegebene Passwort",
      }}
    >
      {(renderProps) => (
        <IconFloatInput
          type="password"
          label="Passwort wiederholen"
          icon="pi-lock"
          {...renderProps}
        />
      )}
    </FormControlled>
  );
}
