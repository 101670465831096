import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import Card from "../../components/card";
import { observer } from "mobx-react";
import IconFloatInput from "../../components/input/IconFloatInput";
import { signInWithGoogle } from "../../api/firebase/auth";
import { Container } from "../../components/container";
import { signinWithMail } from "../../api/firebase/auth";
import PasswordResetDialogButton from "./PasswordResetDialogButton";
import ToastContext from "../../components/toast/ToastContext";

const LoginView: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const showToast = useContext(ToastContext);

  const footer = () => {
    return (
      <>
        <p>
          Sie haben noch keinen Account?{" "}
          <Link to="signup">Hier registrieren</Link>
          <br />
          <PasswordResetDialogButton />
        </p>
      </>
    );
  };

  const showLoginErrorToast = () =>
    showToast({
      severity: "error",
      summary: "Login fehlgeschlagen",
      detail:
        "Bitte prüfen Sie Ihre Anmeldeinformationen und versuchen Sie es erneut",
    });

  const submitOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) handleLogin();
  };

  const handleLogin = () => {
    signinWithMail(email, password, undefined, showLoginErrorToast);
  };

  return (
    <Container containerClassName="login-container p-grid">
      <Card className="login-card p-col" title="Anmelden" footer={footer()}>
        <IconFloatInput
          key="mail-input"
          id="mail-input"
          label="E-Mail"
          type="email"
          value={email}
          keyfilter="email"
          onKeyDown={submitOnEnter}
          onChange={(value) => setEmail(value)}
          icon="pi-user"
        />
        <IconFloatInput
          key="password-input"
          id="password-input"
          label="Passwort"
          type="password"
          value={password}
          onKeyDown={submitOnEnter}
          onChange={(value) => setPassword(value)}
          icon="pi-unlock"
        />
        <div className="p-d-flex p-flex-wrap p-jc-evenly p-ai-center p-mt-4">
          <Button
            disabled={false}
            className="login-button p-button-primary p-mb-2"
            label="Einloggen"
            icon="pi pi-sign-in"
            iconPos="right"
            onClick={handleLogin}
          />
          <Button
            disabled={false}
            className="p-button-primary p-button-text p-mb-2"
            label="Mit Google Anmelden"
            icon="pi pi-google"
            iconPos="left"
            onClick={signInWithGoogle}
          />
        </div>
      </Card>
    </Container>
  );
};

export default observer(LoginView);
