import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import InfoInput from "../../../components/input/InfoInput";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps & {
    disabled: boolean;
  };

export default function CompanyFormField(props: ControlledIconFloatInputProps) {
  return (
    <FormControlled {...props}>
      {(renderProps) => (
        <InfoInput
          infotext="Firma"
          smallLabel=" (optional)"
          disabled={props.disabled}
          {...renderProps}
        />
      )}
    </FormControlled>
  );
}
