import { InputText } from "primereact/inputtext";
import React from "react";
import "primeflex/primeflex.css";
import classnames from "classnames";

export type InfoInputProps = {
  infotext?: string;
  id: string;
  smallLabel?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (event: any) => void;
  onKeyDown?: (event: any) => void;
  error?: boolean;
};

export interface InfoInputData {
  id: string;
  label: string;
  smallLabel?: string;
  value: string | undefined;
  onChange?: (event: any) => void;
  error?: string;
  rules?: { [key: string]: any };
}

const InfoInput: React.FC<InfoInputProps> = (props) => {
  return (
    <div className={props.className}>
      <div className="p-field">
        <label htmlFor={props.id}>{props.infotext}</label>
        {props.smallLabel && <small>{"  "}(optional)</small>}
        <InputText
          className={classnames({
            "p-invalid": props.error,
          })}
          id={props.id}
          disabled={props.disabled}
          type={props.type}
          value={props.value}
          onKeyDown={props.onKeyDown}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};

export default InfoInput;
