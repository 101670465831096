import { onAuthStateChanged, User } from "firebase/auth";
import { makeAutoObservable, runInAction } from "mobx";
import UserDataStore from "./UserDataStore";
import { auth } from "../api/firebase";
import DeviceStore from "./DeviceStore";

class SessionStore {
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
    onAuthStateChanged(auth, (userAuth) => {
      console.log("user changed", userAuth?.email);
      if (userAuth) {
        UserDataStore.getCurrentUser(userAuth?.uid);
      } else {
        console.log("resetting stores");
        UserDataStore.resetStore();
        DeviceStore.resetStore();
      }
      runInAction(async () => {
        this.user = userAuth;
      });
    });
  }
}

export default new SessionStore();
