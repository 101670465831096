import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { Toolbar } from "primereact/toolbar";
import { useState } from "react";
import { ContactType } from "../../api/firebase/types/emergencyContacts.types";
import ContactStore from "../../store/ContactStore";
import ContactTable from "./ContactTable";
import EmergencyContactsToolbarRightElements from "./ContactToolbar";

type ContactTableViewProps = {
  contacts?: ContactType[];
  setContacts: React.Dispatch<React.SetStateAction<ContactType[] | undefined>>;
};

const ContactTableView: React.FC<ContactTableViewProps> = (props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<ContactType[]>();
  const [originalRows, setOriginalRows] = useState({});

  const onContactsDeleted = () => {
    setSelectedContacts(undefined);
    setShowLoader(false);
  };

  // on clicking edit, save original values of the row into originalRows
  const onRowEditInit = (event: any) => {
    let newOriginalRows: any = originalRows;
    newOriginalRows[event.index] = { ...props.contacts![event.index] };
    setOriginalRows(newOriginalRows);
  };

  // on clicking cancel, restore old values from originalRows
  const onRowEditCancel = (event: any) => {
    let contactsCopy = [...props.contacts!];
    contactsCopy[event.index] = (originalRows as any)[event.index];

    let newOriginalRows = originalRows;
    delete (newOriginalRows as any)[event.index];
    props.setContacts(contactsCopy);
  };

  const onRowEditSave = (event: any) => {
    setShowLoader(true);
    ContactStore.updateContact(event.data).finally(() => setShowLoader(false));
  };

  return (
    <Card className="contact-tableview p-col">
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar
        left={() => <h2>Notfallkontakte</h2>}
        right={() => (
          <EmergencyContactsToolbarRightElements
            contactsToDelete={selectedContacts}
            onDelete={onContactsDeleted}
            showLoader={setShowLoader}
          />
        )}
      />
      <div className="p-mb-3">
        <i>
          Tragen Sie hier Ihre Notfallkontakte ein. Dies sind alle Kontakte
          abseits von Feuerwehr, Polizei und Rettungsdienst, also beispielsweise
          auch ein Sicherheits- oder Pflegedienst. Diese Kontakte können im
          Notfall per SMS informiert werden. Details dazu werden im folgenden
          Schritt bei der Geräteeinrichtung definiert.
        </i>
      </div>
      {props.contacts && props.contacts.length > 0 ? (
        <ContactTable
          contacts={props.contacts}
          setContacts={props.setContacts}
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
          onRowEditInit={onRowEditInit}
          onRowEditCancel={onRowEditCancel}
          onRowEditSave={onRowEditSave}
          selection={selectedContacts}
          onSelectionChange={(e) => setSelectedContacts(e.value)}
        />
      ) : (
        <p>
          <i>Keine Notfallkontakte eingerichtet</i>
        </p>
      )}
    </Card>
  );
};

export default ContactTableView;
