import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { FormControlProps } from "../../../components/validation/FormControlled";
import AddressFormField from "./formFields/AddressFormField";
import { fieldIds } from "./formFields/FieldIds";
import PostCodeFormField from "./formFields/PostCodeFormField";
import StreetNumberFormField from "./formFields/StreetNumberFormField";

type SearchLocationFormProps = {
  useFormMethods: FormControlProps;
  locationCandidates?: any[];
  onSubmit: (data: any) => void;
  showLoader: boolean;
};

const SearchLocationForm: React.FC<SearchLocationFormProps> = ({
  useFormMethods,
  locationCandidates,
  onSubmit,
  showLoader,
}) => {
  return (
    <form
      id="search-location-form"
      autoComplete="off"
      onSubmit={useFormMethods.handleSubmit(onSubmit)}
    >
      <div className="p-formgrid p-grid">
        <div className="p-field p-col-8">
          <label htmlFor={fieldIds.street}>Strasse</label>
          <AddressFormField id={fieldIds.street} {...useFormMethods} />
        </div>
        <div className="p-field p-col-4">
          <label htmlFor={fieldIds.number}>Hausnummer</label>
          <StreetNumberFormField id={fieldIds.number} {...useFormMethods} />
        </div>
      </div>
      <div className="p-grid">
        <div className="p-field p-col-6">
          <label htmlFor={fieldIds.postcode}>Postleitzahl</label>
          <PostCodeFormField id={fieldIds.postcode} {...useFormMethods} />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor={fieldIds.city}>Ort</label>
          <AddressFormField id={fieldIds.city} {...useFormMethods} />
        </div>
        {showLoader && (
          <div className="p-field p-col-12">
            <ProgressBar mode="indeterminate" />
          </div>
        )}
        {locationCandidates && locationCandidates.length == 0 && (
          <div className="p-field p-col-12">
            <p>
              <i>
                Ort konnte nicht gefunden werden, bitte prüfen Sie die
                eingegebene Adresse.
              </i>
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchLocationForm;
