import { ProgressSpinner } from "primereact/progressspinner";

const LoaderInCenter = () => {
  return (
    <div className="loader-in-center">
      <ProgressSpinner />
    </div>
  );
};

export default LoaderInCenter;
