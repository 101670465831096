import { User } from "firebase/auth";
import { observer } from "mobx-react";
import React, { createContext } from "react";
import SessionStore from "./SessionStore";

export const UserContext = createContext<User | null>(null);

const UserProvider: React.FC = (props) => {
  const user = SessionStore.user;

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export default observer(UserProvider);
