import { Button, ButtonProps } from "primereact/button";
import React from "react";

type TooltipButtonProps = Omit<ButtonProps, "tooltip"> & {
  tooltip: string;
};

const TooltipButton: React.FC<TooltipButtonProps> = (props) => {
  return (
    <Button
      icon={props.icon || "pi pi-info-circle"}
      readOnly
      className="p-button-rounded p-button-text"
      tooltip={props.tooltip}
    />
  );
};

export default TooltipButton;
