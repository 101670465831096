import { locale, addLocale } from "primereact/api";

addLocale("de", {
  firstDayOfWeek: 1,
  dayNames: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  dayNamesMin: ["S", "M", "D", "M", "D", "F", "S"],
  monthNames: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "März",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Okt",
    "Nov",
    "Dez",
  ],
  dateFormat: "dd.mm.yy",
  today: "Heute",
  weak: "Schwach",
  medium: "Mittel",
  strong: "Stark",
  accept: "Ja",
  reject: "Nein",
  choose: "Wählen",
  upload: "Hochladen",
  cancel: "Abbrechen",
  clear: "Leeren",
  weekHeader: "KW",
  passwordPrompt: "Passwort eingeben",
});

locale("de");
