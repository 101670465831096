import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  collection,
  CollectionReference,
  connectFirestoreEmulator,
  DocumentData,
  enableMultiTabIndexedDbPersistence,
  initializeFirestore,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import config from "../../config";

import * as firebaseJSON from "./../../firebase.json";

let FIRESTORE_EMULATOR_HOST;

const app = initializeApp(config.firebase_config);
const auth = getAuth();
auth.useDeviceLanguage();

const firestore = initializeFirestore(app, { ignoreUndefinedProperties: true });
enableMultiTabIndexedDbPersistence(firestore);

const functions = getFunctions(app, config.region);

if (config.useEmulator) {
  connectAuthEmulator(
    auth,
    `http://localhost:${firebaseJSON.emulators.auth.port}`
  );
  connectFirestoreEmulator(
    firestore,
    "localhost",
    firebaseJSON.emulators.firestore.port
  );
  FIRESTORE_EMULATOR_HOST = `localhost:${firebaseJSON.emulators.auth.port}`;
  connectFunctionsEmulator(
    functions,
    "localhost",
    firebaseJSON.emulators.functions.port
  );
}

const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

export {
  app,
  auth,
  firestore,
  functions,
  createCollection,
  FIRESTORE_EMULATOR_HOST,
};
