import { observer } from "mobx-react";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { useForm } from "react-hook-form";
import SaveCancelFooter from "../../components/footer/SaveCancelFooter";
import PhoneAuthDialogButton from "./phoneAuth/PhoneAuthDialogButton";
import UpdateEmailButton from "./UpdateEmailButton";
import UpdatePasswordButton from "./UpdatePasswordButton";
import UserDataForm from "./UserDataForm";
import { ProgressBar } from "primereact/progressbar";
import { UserDataType } from "../../api/firebase/types/userData.types";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import classNames from "classnames";
import { Divider } from "primereact/divider";

export type UserDataCardProps = {
  userData?: UserDataType;
};

const UserDataCard: React.FC<UserDataCardProps> = (props) => {
  const [isEditingUserData, setIsEditingUserData] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const useFormMethods = useForm();
  const [width] = useMediaQuery();

  const onCancelUserData = () => {
    setIsEditingUserData(false);
    useFormMethods.reset();
  };

  const onFinishEditing = () => {
    setShowLoader(false);
    setIsEditingUserData(false);
  };

  const headerUserData = !isEditingUserData && (
    <Button
      key="edit-button"
      icon="pi pi-pencil"
      className="p-button-text"
      style={{ height: 40 }}
      label="Bearbeiten"
      onClick={() => setIsEditingUserData(true)}
    />
  );

  return (
    <Card
      className={classNames("userdata-card p-col p-mb-2", {
        "p-mr-2": width >= screenSize.tablet,
      })}
      footer={
        isEditingUserData && (
          <SaveCancelFooter
            cancelAction={onCancelUserData}
            formId="userdata-form"
          />
        )
      }
    >
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar left={() => <h2>Stammdaten</h2>} right={() => headerUserData} />
      <PhoneAuthDialogButton
        phone={props.userData?.phone}
        isEditing={isEditingUserData}
      />
      <UpdateEmailButton isEditing={isEditingUserData} />
      <UpdatePasswordButton isEditing={isEditingUserData} />
      <Divider />
      <UserDataForm
        useFormMethods={useFormMethods}
        userData={props.userData}
        onSubmit={onFinishEditing}
        onError={onFinishEditing}
        showLoader={() => setShowLoader(true)}
        editable={isEditingUserData}
      />
    </Card>
  );
};

export default observer(UserDataCard);
