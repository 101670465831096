import moment from "moment";
import "moment/locale/de";
import { BrowserRouter } from "react-router-dom";
import PageLayout from "./components/pageLayout/PageLayout";
import { ToastProvider } from "./components/toast/ToastContext";
import UserProvider from "./store/UserProvider";
import PageRouter from "./navigation";

function App() {
  moment.locale("de");
  return (
    <div className="App">
      <BrowserRouter>
        <UserProvider>
          <ToastProvider>
            <PageLayout>
              <PageRouter />
            </PageLayout>
          </ToastProvider>
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
