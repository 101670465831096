import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ContactType } from "../../api/firebase/types/emergencyContacts.types";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import ContactStore from "../../store/ContactStore";
import ContactItemView from "./ContactItemView";
import ContactTableView from "./ContactTableView";

const ContactsView: React.FC<{ isOnboarding?: boolean }> = (props) => {
  const contactDocs = ContactStore.contacts;
  const [contacts, setContacts] = useState<ContactType[]>();

  const [width] = useMediaQuery();

  useEffect(() => {
    setContacts(contactDocs);
  }, [ContactStore.contacts]);

  return (
    <div className="contactview-container p-grid">
      {width >= screenSize.tablet ? (
        <div
          className={classNames({
            "p-d-flex p-jc-center": props.isOnboarding,
            "p-px-3": !props.isOnboarding || width >= screenSize.tablet,
          })}
          style={{ width: "100%" }}
        >
          <ContactTableView contacts={contacts} setContacts={setContacts} />
        </div>
      ) : (
        <ContactItemView contacts={contacts} />
      )}
    </div>
  );
};

export default observer(ContactsView);
