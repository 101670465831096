import { httpsCallable } from "firebase/functions";
import { functions } from "..";

export const getLocationsFromGoogle = async (address: string) => {
  console.log("getting location ..");
  const getLocation = httpsCallable<
    { address: string },
    { status: string; candidates: any }
  >(functions, "googleMaps-getLocation");
  try {
    const res = await getLocation({
      address,
    });
    console.log(res);

    if (res.data.status !== "OK") {
      return [];
    }
    return res.data.candidates;
  } catch (err) {
    alert("Fehler bei Suche, prüfen Sie Ihre Internetverbindung");
    console.log(err);
    return [];
  }
};
