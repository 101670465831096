import {
  deleteDoc,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { createCollection, firestore } from "..";
import UserDataStore from "../../../store/UserDataStore";
import { CollectionName } from "../types/collections.types";
import { IncidentType } from "../types/incident.types";

const incidentCollection = createCollection<IncidentType>(
  CollectionName.INCIDENTS
);

export const subscribeIncidents = (
  onNewSnapshot: (incidents: IncidentType[]) => void,
  onError: (err: FirestoreError) => void
) => {
  const user = UserDataStore.currentUser;
  if (!user?.userId) return;

  const incidentQuery = query(
    incidentCollection,
    where("userId", "==", user?.userId),
    orderBy("created", "desc")
  );

  const unsubscribe = onSnapshot(
    incidentQuery,
    (querySnapshot) => {
      const incidents = querySnapshot.docs.map((doc) => {
        const newIncident = { ...doc.data(), _id: doc.id };
        return newIncident;
      });
      onNewSnapshot(incidents);
    },
    (err) => onError(err)
  );
  return unsubscribe;
};

export const deleteIncident = async (incidentId: string) => {
  const incidentRef = doc(firestore, CollectionName.INCIDENTS, incidentId);
  return await deleteDoc(incidentRef);
};

export default incidentCollection;
