import { ToastMessageType } from "../components/toast/ToastContext";

export const EmailVerificationToasts: { [key: string]: ToastMessageType } = {
  verificationMailSent: {
    severity: "success",
    summary: "Verifizierungsmail gesendet",
    detail:
      "Es wurde eine Verifizierungsmail an die angegebene Email Adresse versendet",
  },
  sendVerificationMailError: {
    severity: "error",
    summary: "Verifizierungsmail nicht gesendet",
    detail:
      "Es konnte keine Verifizierungsmail an die angegebene Email Adresse versendet werden",
  },
};
