import {
  addDoc,
  deleteDoc,
  doc,
  FirestoreError,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { createCollection, firestore } from "..";
import UserDataStore from "../../../store/UserDataStore";
import { CollectionName } from "../types/collections.types";
import { ContactType } from "../types/emergencyContacts.types";

export const emergencyContactCollection = createCollection<ContactType>(
  CollectionName.EMERGENCY_CONTACTS
);

export const subscribeContacts = (
  onNewSnapshot: (contacts: ContactType[]) => void,
  onError: (err: FirestoreError) => void
) => {
  const user = UserDataStore.currentUser;
  if (!user?.userId) return;

  const contactQuery = query(
    emergencyContactCollection,
    where("userId", "==", user?.userId)
  );

  const unsubscribe = onSnapshot(
    contactQuery,
    (querySnapshot) => {
      const contacts = querySnapshot.docs.map((doc) => {
        const newContact = { ...doc.data(), id: doc.id };
        return newContact;
      });
      onNewSnapshot(contacts);
    },
    (err) => onError(err)
  );
  return unsubscribe;
};

export const addContact = async (contact: ContactType) => {
  try {
    await addDoc(emergencyContactCollection, contact);
  } catch (e) {
    console.error(e);
  }
};

export const updateContact = async (contact: ContactType) => {
  const contactRef = doc(
    firestore,
    CollectionName.EMERGENCY_CONTACTS,
    contact.id
  );
  await setDoc(contactRef, contact, { merge: true });
};

export const deleteContact = async (contactId: string) => {
  const contactRef = doc(
    firestore,
    CollectionName.EMERGENCY_CONTACTS,
    contactId
  );
  await deleteDoc(contactRef);
};

export default emergencyContactCollection;
