import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { getLocationsFromGoogle } from "../../../api/firebase/functionCallables/googleCallables";
import { DeviceLocation } from "../../../api/firebase/types/devices.types";
import { fieldIds } from "./formFields/FieldIds";
import LocationResultPreview from "./LocationResultList";
import SearchLocationForm from "./SearchLocationForm";

type UpdateAdressInputProps = {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
  location?: DeviceLocation;
  handleLocationChange: (location: DeviceLocation) => void;
};

const LocationModal: React.FC<UpdateAdressInputProps> = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [locationCandidates, setLocationCandidates] = useState<any[]>();

  const defaultValues = {
    [fieldIds.street]: undefined,
    [fieldIds.number]: undefined,
    [fieldIds.postcode]: undefined,
    [fieldIds.city]: undefined,
  };

  const useFormMethods = useForm({
    defaultValues,
  });

  const handleSelect = (location: DeviceLocation) => {
    props.handleLocationChange(location);
    props.setVisible(false);
  };

  const handleClose = () => {
    props.setVisible(false);
    setLocationCandidates(undefined);
  };

  const handleSelectFromAPI = (candidate: any) => {
    const currLocation = {
      name: candidate.formatted_address,
      lat: candidate.geometry.location.lat,
      lng: candidate.geometry.location.lng,
      locationId: candidate.place_id,
    };
    handleSelect(currLocation);
    setLocationCandidates(undefined);
  };

  const onSubmitLocation = async (data: any) => {
    const searchString = `${data.street} ${data.number}, ${data.postcode} ${data.city}`;
    setShowLoader(true);
    const candidates: any = await getLocationsFromGoogle(
      encodeURI(searchString)
    );
    setShowLoader(false);
    setLocationCandidates(candidates);
  };

  const handleCancel = () => {
    props.setVisible(false);
    setLocationCandidates(undefined);
  };

  const dialogFooter = () => {
    return (
      <>
        <Button
          disabled={locationCandidates && locationCandidates?.length < 1}
          label="Weiter"
          form="search-location-form"
          onClick={() =>
            locationCandidates && handleSelectFromAPI(locationCandidates[0])
          }
        />
        <Button
          label="Abbrechen"
          type="button"
          className="p-button-secondary p-button-text"
          onClick={handleCancel}
        />
      </>
    );
  };

  return (
    <Dialog
      visible={props.isVisible}
      header="Vollständige Adresse zuweisen"
      modal
      className="p-fluid location-modal"
      footer={dialogFooter}
      onHide={handleClose}
    >
      {locationCandidates && locationCandidates.length > 0 ? (
        <LocationResultPreview locationCandidates={locationCandidates[0]} />
      ) : (
        <SearchLocationForm
          locationCandidates={locationCandidates}
          onSubmit={onSubmitLocation}
          showLoader={showLoader}
          useFormMethods={useFormMethods}
        />
      )}
    </Dialog>
  );
};

export default LocationModal;
