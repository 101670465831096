import React, { createContext, useRef, useState } from "react";
import { Toast, ToastMessage } from "primereact/toast";
import { useHistory } from "react-router";
import { NavItem, navItems } from "../../navigation/NavItems";

interface NavigationContextType {
  activeNavItem: NavItem;
  setActiveNavItem: (item: NavItem) => void;
}

export const NavigationContext = createContext<NavigationContextType>({
  activeNavItem: navItems[0],
  setActiveNavItem: () => navItems[0],
});

export const NavigationProvider: React.FC = (props) => {
  const [activeNavbarItem, setActiveNavbarItem] = useState<NavItem>(
    navItems[0]
  );

  return (
    <NavigationContext.Provider
      value={{
        activeNavItem: activeNavbarItem,
        setActiveNavItem: setActiveNavbarItem,
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
