import { EmergencyCall } from "./incident.types";

export type DeviceLocation = {
  name: string;
  lat?: number;
  lng?: number;
  locationId?: string;
};

export enum DeviceTypeCategory {
  "DESKTOP_CLIENT_BUTTON" = "DESKTOP_CLIENT_BUTTON",
  "HARDWARE_DEVICE" = "HARDWARE_DEVICE",
}
export type DeviceType = {
  id: string;
  serialNumber: string;
  deviceId: string;
  name: string;
  userId: string;
  location: DeviceLocation;
  emergencyCall: EmergencyCall;
  contactIdsToNotify?: string[];
  incidentDetails: string;
  deviceTestMode?: boolean; // UserBO Device Testing Mode
  testWithFax: boolean; // internal real fax api testing mode
  typeCategory: DeviceTypeCategory;
};

export interface DeviceBatteryType {
  value: string;
  date: moment.Moment;
}
