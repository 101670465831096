import {
  linkWithCredential,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  unlink,
} from "firebase/auth";
import SessionStore from "../../store/SessionStore";
import { auth } from "./index";

let recaptchaVerifier: RecaptchaVerifier;

export const addCaptchaToWindow = () => {
  //@ts-ignore
  window.recaptchaVerifier = new RecaptchaVerifier(
    "phone-auth-button",
    {
      size: "invisible",
    },
    auth
  );
  //@ts-ignore
  recaptchaVerifier = window.recaptchaVerifier;
};

export const authenticateWithPhone = async (phoneNumber: string) => {
  if (!recaptchaVerifier) {
    addCaptchaToWindow();
  }
  if (!recaptchaVerifier) {
    console.error("there is no appVerifier present");
    return;
  }
  return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
};

export const checkVerificationCode = async (
  verificationId: string,
  verificationCode: string
) => {
  const credential = PhoneAuthProvider.credential(
    verificationId,
    verificationCode
  );
  const alreadyHasPhone = SessionStore.user!.providerData.some(
    (provider) => provider?.providerId === "phone"
  );
  if (alreadyHasPhone) {
    await unlink(SessionStore.user!, "phone");
  }
  return linkWithCredential(SessionStore.user!, credential);
};
