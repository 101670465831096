import { makeAutoObservable, runInAction } from "mobx";
import { UserDataType } from "../api/firebase/types/userData.types";
import {
  addUserData,
  getCurrentUser,
  updateUserData,
} from "../api/firebase/collections/userDataCollection";

class UserDataStore {
  error?: any = null;
  isPending: boolean = false;
  currentUser?: UserDataType;

  constructor() {
    makeAutoObservable(this);
  }

  getCurrentUser = async (userId: string) => {
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      const currentUser = await getCurrentUser(userId);
      runInAction(() => {
        this.isPending = false;
        this.error = undefined;
        this.currentUser = currentUser;
      });
    } catch (err) {
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
    }
  };

  addUserDataDoc = async (userData: Partial<UserDataType>) => {
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      await addUserData(userData);
      const createdUser = await getCurrentUser(userData.userId);
      runInAction(() => {
        this.isPending = false;
        this.error = undefined;
        this.currentUser = createdUser;
      });
    } catch (err) {
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
    }
  };

  updateUserDataDoc = async (newUserData: Partial<UserDataType>) => {
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      await updateUserData({
        ...newUserData,
        userId: this.currentUser!.userId,
      });
      const updatedUserData = await getCurrentUser(this.currentUser!.userId);
      runInAction(() => {
        this.isPending = false;
        this.error = undefined;
        this.currentUser = updatedUserData;
      });
    } catch (err) {
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
    }
  };

  resetStore = () => {
    runInAction(() => {
      this.error = null;
      this.isPending = false;
      this.currentUser = undefined;
    });
  };
}

export default new UserDataStore();
