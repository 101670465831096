import React from "react";
import { DeviceLocation } from "../../../../api/firebase/types/devices.types";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../../components/validation/FormControlled";
import UpdateAdressInput from "../../deviceLocation/UpdateAdressInput";

type LocationFormFieldProps = FormBehaviourProps<DeviceLocation> &
  FormControlProps;

const LocationFormField: React.FC<LocationFormFieldProps> = (props) => {
  return (
    <FormControlled
      {...props}
      rules={{ required: true }}
      errorMessages={{ required: "Bitte geben Sie eine gültige Adresse ein" }}
    >
      {({ onChange, ...renderProps }) => (
        <UpdateAdressInput
          location={renderProps.value}
          handleLocationChange={onChange}
          {...renderProps}
        />
      )}
    </FormControlled>
  );
};
export default LocationFormField;
