import { InputText } from "primereact/inputtext";
import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../../components/validation/FormControlled";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps;

export default function StreetNumberFormField(
  props: ControlledIconFloatInputProps
) {
  return (
    <FormControlled
      {...props}
      rules={{ required: true }}
      errorMessages={{
        required: "Angabe fehlt",
      }}
    >
      {(renderProps) => <InputText {...renderProps} />}
    </FormControlled>
  );
}
