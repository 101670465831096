import React, { useContext, useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import {
  authenticateWithPhone,
  checkVerificationCode,
} from "../../../api/firebase/phoneAuth";
import { InputNumber } from "primereact/inputnumber";
import ButtonInput from "../../../components/input/ButtonInput";
import UserDataStore from "../../../store/UserDataStore";
import { Message } from "primereact/message";
import { observer } from "mobx-react";
import ToastContext from "../../../components/toast/ToastContext";
import classnames from "classnames";
import ToastMessages from "../../../toastMessages/phoneAuthToastMessages";
import CountryCodeDropdown, { CountryCodeOption } from "./CountryCodeDropdown";
import { countryCodes } from "../../../api/phoneRegistration/countryCodes";
import { Toolbar } from "primereact/toolbar";
import { Card } from "primereact/card";
import TooltipButton from "../../../components/buttons/TooltipButton";
import { ConfirmationResult } from "firebase/auth";

export type PhoneAuthProps = {
  verifiedPhone?: string;
};

const PhoneAuthStep: React.FC<PhoneAuthProps> = (props) => {
  const [phone, setPhone] = useState<number>();
  const [authResult, setAuthResult] = useState<ConfirmationResult>();
  const [selectedCountry, setSelectedCountry] = useState<CountryCodeOption>();
  const [showVerificationCodeField, setShowVerificationCodeField] =
    useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [noPhoneError, setNoPhoneError] = useState<boolean>(false);
  const [noCountryError, setNoCountryError] = useState<boolean>(false);
  const [noCodeError, setNoCodeError] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const showToast = useContext(ToastContext);

  useEffect(() => {
    const initialCountry = countryCodes.find(
      (country) => country.name === "Germany"
    );
    setSelectedCountry(initialCountry);
  }, []);

  const handlePhoneAuth = () => {
    let error = false;
    if (!phone) {
      setNoPhoneError(true);
      error = true;
    }
    if (!selectedCountry) {
      setNoCountryError(true);
      error = true;
    }
    if (error) return;
    setShowLoader(true);
    authenticateWithPhone(selectedCountry!.code + phone)
      .then((ConfirmationResult) => {
        showToast(ToastMessages.verificationCodeSent);
        setAuthResult(ConfirmationResult);
        setShowVerificationCodeField(true);
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          showToast(ToastMessages.tooManyRequests);
        }
        showToast(ToastMessages.verificationCodeSendError);
        console.error(error);
      })
      .finally(() => setShowLoader(false));
  };

  const handleVerificationCodeSubmit = (code?: string) => {
    if (!code) {
      setNoCodeError(true);
      return;
    }
    setShowLoader(true);
    checkVerificationCode(authResult!.verificationId, code)
      .then((credential) => {
        setVerificationError(false);
        setShowVerificationCodeField(false);
        showToast(ToastMessages.verificationSuccessfull);
        UserDataStore.updateUserDataDoc({
          phone: credential.user!.phoneNumber!,
        });
      })
      .catch((error) => {
        setVerificationError(true);
        console.error(error);
        if (error.code === "auth/invalid-verification-code") {
          showToast(ToastMessages.wrongVerificationCode);
        } else if (error.code === "auth/user-disabled") {
          showToast(ToastMessages.phoneNumberBlocked);
        } else if (error.code === "auth/credential-already-in-use") {
          showToast(ToastMessages.phoneAlreadyInUse);
        } else {
          showToast(ToastMessages.verificationFailed);
        }
      })
      .finally(() => setShowLoader(false));
  };

  const handleCountryCodeChange = (selectedCountry: CountryCodeOption) => {
    selectedCountry && setNoCountryError(false);
    setSelectedCountry(selectedCountry);
  };

  return (
    <Card className="phoneauth-card p-col">
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar
        className="p-jc-center"
        left={() => <h2>Telefonnummer authentifizieren</h2>}
      />
      <div className="p-text-center p-mb-3">
        <i>
          Dieser Schritt ist notwendig, um Sie als Halter der Telefonnummer
          eindeutig zu identifizieren. Über die angegebene Telefonnummer muss im
          nächsten Schritt eine SMS empfangen werden.
        </i>
      </div>
      <div className="p-grid p-ai-center p-jc-center">
        <CountryCodeDropdown
          className="p-mb-2 p-mr-2"
          selectedCountry={selectedCountry}
          onChange={handleCountryCodeChange}
          error={noCountryError}
          disabled
        />
        <InputNumber
          className={classnames("p-mb-2", "p-mr-2", {
            "p-invalid": noPhoneError,
          })}
          value={phone}
          placeholder="z.B. 1737218433"
          useGrouping={false}
          onChange={(e) => {
            e.value && setNoPhoneError(false);
            setPhone(e.value);
          }}
        />
        <div className="p-mb-2">
          <Button
            id="phone-auth-button"
            type="button"
            label={authResult ? "Code erneut senden" : "Authentifizieren"}
            onClick={handlePhoneAuth}
          />
          <TooltipButton tooltip="Um eine ausländische Telefonnummer zu registrieren, kontaktieren Sie bitte den Support unter mail@instant-help.de" />
        </div>
        {noPhoneError && (
          <small id="phone" className="p-error p-d-block form-error">
            Bitte tragen Sie eine gültige Telefonnummer ein
          </small>
        )}
        {showVerificationCodeField && (
          <ButtonInput
            className="verification-code-field p-field p-col-fixed"
            buttonIcon="pi-check-circle"
            placeholder="Verifizierungscode eingeben"
            disabled={!showVerificationCodeField}
            onClick={(code) => handleVerificationCodeSubmit(code)}
          />
        )}
        {showVerificationCodeField && noCodeError && (
          <small
            id="verification-code"
            className="p-error p-d-block form-error"
          >
            Bitte tragen Sie den Verifizierungscode ein
          </small>
        )}
        {props.verifiedPhone && (
          <Message
            className="p-mt-4 p-col-12"
            severity="success"
            text={props.verifiedPhone + " Erfolgreich verifiziert"}
          />
        )}
        {verificationError && (
          <Message
            className="p-mt-4 p-col-12"
            severity="error"
            text="Nummer konnte nicht verifiziert werden"
          />
        )}
      </div>
    </Card>
  );
};

export default observer(PhoneAuthStep);
