import { User } from "firebase/auth";
import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { TabMenu } from "primereact/tabmenu";
import { Toolbar } from "primereact/toolbar";
import { useContext } from "react";
import { useHistory } from "react-router";
import { logout } from "../../api/firebase/auth";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import { NavItem, navItems } from "../../navigation/NavItems";
import UserDataStore from "../../store/UserDataStore";
import { Container } from "../container";
import NavigationContext from "../pageLayout/NavgationContext";
import SidebarMenu from "./SidebarMenu";

type TabHeaderProps = {
  user: User | null;
};

export const HeaderToolbar: React.FC<TabHeaderProps> = ({ user }) => {
  const userData = UserDataStore.currentUser;

  const [width] = useMediaQuery();
  const history = useHistory();

  const { activeNavItem, setActiveNavItem } = useContext(NavigationContext);
  const tabChange = (item: NavItem) => {
    setActiveNavItem(item);
    history.push(item.path);
  };

  return (
    <Container flexDirection="row" containerClassName="header-toolbar">
      <Toolbar
        left={() =>
          user &&
          userData?.finishedOnboarding &&
          (width >= screenSize.laptop ? (
            <TabMenu
              className="header-tabmenu"
              model={navItems}
              activeItem={activeNavItem}
              onTabChange={(e: any) => tabChange(e.value)}
            />
          ) : (
            <SidebarMenu />
          ))
        }
        right={() =>
          user && (
            <Button
              label="Logout"
              className="p-button-outlined"
              icon="pi pi-power-off"
              onClick={logout}
            />
          )
        }
      />
    </Container>
  );
};

export default observer(HeaderToolbar);
