import React from "react";
import { Column } from "primereact/column";
import { DataTable, DataTableProps } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ContactType } from "../../api/firebase/types/emergencyContacts.types";

type ContactTableProps = Omit<DataTableProps, "value"> & {
  contacts?: ContactType[];
  selectedContacts?: ContactType[];
  setContacts: React.Dispatch<React.SetStateAction<ContactType[] | undefined>>;
  setSelectedContacts: React.Dispatch<
    React.SetStateAction<ContactType[] | undefined>
  >;
};

const ContactTable: React.FC<ContactTableProps> = (props) => {
  const onEditorValueChange = (editorProps: any, value: any) => {
    let updatedContacts = [...editorProps.value];
    updatedContacts[editorProps.rowIndex][editorProps.field] = value;
    props.setContacts(updatedContacts);
  };

  const inputTextEditor = (props: any, field: string) => {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) => onEditorValueChange(props, e.currentTarget.value)}
      />
    );
  };

  return (
    <div className="p-fluid">
      <DataTable
        className="p-datatable-striped"
        value={props.contacts}
        editMode="row"
        dataKey="id"
        onRowEditInit={props.onRowEditInit}
        onRowEditCancel={props.onRowEditCancel}
        onRowEditSave={props.onRowEditSave}
        selection={props.selectedContacts}
        onSelectionChange={(e) => props.setSelectedContacts(e.value)}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3em" }}
        ></Column>
        <Column
          field="firstName"
          header="Vorname"
          editor={(props) => inputTextEditor(props, "firstName")}
        ></Column>
        <Column
          field="lastName"
          header="Nachname"
          editor={(props) => inputTextEditor(props, "lastName")}
        ></Column>
        <Column
          field="email"
          header="E-Mail"
          editor={(props) => inputTextEditor(props, "email")}
        ></Column>
        <Column
          field="phone"
          header="Telefonnummer"
          editor={(props) => inputTextEditor(props, "phone")}
        ></Column>
        <Column
          rowEditor
          headerStyle={{ width: "7rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default ContactTable;
