import { Button } from "primereact/button";
import React, { useState } from "react";
import { DeviceLocation } from "../../../api/firebase/types/devices.types";
import TooltipButton from "../../../components/buttons/TooltipButton";
import LocationModal from "./LocationModal";

type UpdateAdressInputProps = {
  location?: DeviceLocation;
  handleLocationChange: (location: DeviceLocation) => void;
};

const UpdateAdressInput: React.FC<UpdateAdressInputProps> = (props) => {
  const [locationModalVisible, setLocationModalVisible] = useState(false);

  const handleSelect = (location: DeviceLocation) => {
    props.handleLocationChange(location);
    setLocationModalVisible(false);
  };

  return (
    <div className="p-d-flex p-flex-wrap">
      <div className="p-d-flex p-ai-center">
        <b className="userdata-p">SOS-Adresse:</b>
        <b className="p-ml-3">
          {props.location?.name || "Keine Adresse angegeben"}
        </b>
      </div>
      <div>
        <Button
          type="button"
          id="update-address-btn"
          className="p-button-text"
          label={props.location?.name ? "Ändern" : "Festlegen"}
          onClick={(e) => setLocationModalVisible(true)}
        />
        <LocationModal
          isVisible={locationModalVisible}
          setVisible={setLocationModalVisible}
          location={props.location}
          handleLocationChange={handleSelect}
        />
        <TooltipButton
          tooltip="Bitte geben Sie die Adresse ein, an welcher das Gerät eingesetzt
            wird."
        />
      </div>
    </div>
  );
};

export default UpdateAdressInput;
