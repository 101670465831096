import { observer } from "mobx-react";
import React, { useState } from "react";
import PhoneAuthStep from "./steps/PhoneAuthStep";
import UserDataStep from "./steps/UserDataStep";
import DevicesStep from "./steps/devices/DevicesStep";
import DiseaseStep from "./steps/DiseaseStep";
import ContactsStep from "./steps/ContactStep";
import { Container } from "../../components/container";
import UserDataStore from "../../store/UserDataStore";
import OnboardingStepper from "./OnboardingStepper";
import OnboardingToolbar from "./OnboardingToolbar";
import { useHistory } from "react-router-dom";
import EmailVerificationIncomplete from "./EmailVerificationIncomplete";
import SessionStore from "../../store/SessionStore";

const OnboardingView: React.FC = () => {
  const user = SessionStore.user;
  const userData = UserDataStore.currentUser;
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();

  const handleStep = (newIndex: number) => {
    if (newIndex > stepArray.length - 1) newIndex = stepArray.length - 1;
    else if (newIndex < 0) newIndex = 0;
    setActiveIndex(newIndex);
  };

  const stepArray = [
    <PhoneAuthStep verifiedPhone={userData?.phone} />,
    <UserDataStep
      userData={userData}
      onSubmit={() => handleStep(activeIndex + 1)}
    />,
    <DiseaseStep
      diseases={userData?.diseases}
      hasHearingImpairment={userData?.hasHearingImpairment}
      onSubmit={() => handleStep(activeIndex + 1)}
    />,
    <ContactsStep />,
    <DevicesStep userData={userData} />,
  ];

  const handleFinishOnboarding = () => {
    UserDataStore.updateUserDataDoc({ finishedOnboarding: true });
    history.push("/");
  };

  if (!user?.emailVerified) return <EmailVerificationIncomplete user={user} />;
  return (
    <Container flexDirection="column" containerClassName="onboarding-container">
      <OnboardingStepper activeIndex={activeIndex} />
      <div className="onboarding-content-container p-grid">
        {stepArray[activeIndex]}
      </div>
      <OnboardingToolbar
        activeIndex={activeIndex}
        stepCount={stepArray.length}
        handleStep={handleStep}
        hasVerifiedPhone={user.phoneNumber !== undefined}
        handleFinishOnboarding={handleFinishOnboarding}
        useSubmit={activeIndex === 1 || activeIndex === 2}
        formId="onboarding-form"
      />
    </Container>
  );
};

export default observer(OnboardingView);
