import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { Toolbar } from "primereact/toolbar";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import UserDataForm from "../../userData/UserDataForm";
import { UserDataProps } from "../../userData/UserDataForm";

const SignupUserDataStep: React.FC<Omit<UserDataProps, "useFormMethods">> = (
  props
) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const onFinishEditing = () => {
    setShowLoader(false);
    props.onSubmit && props.onSubmit();
  };

  const useFormMethods = useForm();

  return (
    <Card className="userdata-card p-col">
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar left={() => <h2>Stammdaten</h2>} />
      <UserDataForm
        useFormMethods={useFormMethods}
        userData={props.userData}
        onSubmit={onFinishEditing}
        onError={onFinishEditing}
        showLoader={() => setShowLoader(true)}
        formId="onboarding-form"
      />
    </Card>
  );
};

export default SignupUserDataStep;
