import { observer } from "mobx-react";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoaderInCenter from "../components/loaders/LoaderInCenter";
import AlarmView from "../pages/alarms/AlarmView";
import ContactView from "../pages/contacts/ContactView";
import DeviceView from "../pages/device/DeviceView";
import HomeView from "../pages/home/HomeView";
import OnboardingView from "../pages/onboarding/OnboardingView";
import UserDataView from "../pages/userData/UserDataView";
import ContactStore from "../store/ContactStore";
import DeviceStore from "../store/DeviceStore";
import IncidentStore from "../store/IncidentStore";
import UserDataStore from "../store/UserDataStore";
import { NavLink } from "./NavLinks";

const AppRouter = () => {
  const userData = UserDataStore.currentUser;

  useEffect(() => {
    IncidentStore.subscribeIncidents();
    DeviceStore.subscribeDevices();
    ContactStore.subscribeContacts();
    return () => {
      IncidentStore.unsubscribeIncidents &&
        IncidentStore.unsubscribeIncidents();
      DeviceStore.unsubscribeDevices && DeviceStore.unsubscribeDevices();
      ContactStore.unsubscribeContacts && ContactStore.unsubscribeContacts();
    };
  }, [userData]);

  if (!userData) return <LoaderInCenter />;

  /**
   * Nutzer ist angemeldet und das Onboarding noch nicht gemacht
   */
  if (!userData.finishedOnboarding)
    return (
      <Switch>
        <Route path={NavLink.ONBOARDING} component={OnboardingView} exact />
        <Redirect to={NavLink.ONBOARDING} />
      </Switch>
    );

  return (
    <Switch>
      <Route path={NavLink.START} component={HomeView} exact />
      <Route path={NavLink.USER} component={UserDataView} exact />
      <Route path={NavLink.ALARMS} component={AlarmView} exact />
      <Route path={NavLink.DEVICES} component={DeviceView} exact />
      <Route path={NavLink.CONTACTS} component={ContactView} exact />
      <Redirect to={NavLink.START} />
    </Switch>
  );
};

export default observer(AppRouter);
