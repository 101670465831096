import classNames from "classnames";
import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useState } from "react";
import { DeviceType } from "../../../api/firebase/types/devices.types";
import { ContactType } from "../../../api/firebase/types/emergencyContacts.types";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import SaveCancelFooter from "../../../components/footer/SaveCancelFooter";
import ToastContext from "../../../components/toast/ToastContext";
import { FormControlProps } from "../../../components/validation/FormControlled";
import { useMediaQuery } from "../../../hooks/responsiveLayout";
import ContactStore from "../../../store/ContactStore";
import DeviceStore from "../../../store/DeviceStore";
import { DeviceToasts } from "../../../toastMessages/deviceToastMessages";
import AddContactDialog from "../../contacts/AddContactDialog";
import ContactsFormField from "./formFields/ContactsFormField";
import EmergencyOptionsFormField from "./formFields/EmergencyOptionsFormField";
import LocationFormField from "./formFields/LocationFormField";
import SilentMessageFormField from "./formFields/SilentMessageFormField";
import UserDataStore from "../../../store/UserDataStore";

type DeviceSettingsCardProps = {
  currentDevice: DeviceType;
  useFormMethods: FormControlProps;
};

export const fieldIds = {
  incidentDetails: "incidentDetails",
  contactsToNotify: "contactIdsToNotify",
  emergencyCallOption: "emergencyCall",
  location: "location",
};

const DeviceSettingsCard: React.FC<DeviceSettingsCardProps> = ({
  currentDevice,
  useFormMethods,
}) => {
  const [width] = useMediaQuery();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [addContactDialogVisible, setAddContactDialogVisible] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const contacts = ContactStore.contacts;
  const emergencyOptions = UserDataStore.currentUser?.emergencyOptions;
  const showEmergencyOptions = emergencyOptions?.length !== 1;
  const showToast = useContext(ToastContext);

  const onCancelDeviceSettings = () => {
    useFormMethods.reset();
    DeviceStore.resetCurrentDevice();
  };

  const updateCurrentDevice = (data: any) => {
    const updatedDevice: Partial<DeviceType> = {
      ...data,
      contactIdsToNotify: data.contactIdsToNotify
        ? data.contactIdsToNotify.map((contact: ContactType) => contact.id)
        : [],
    };
    if (emergencyOptions?.length === 1)
      updatedDevice.emergencyCall = emergencyOptions[0];
    setShowLoader(true);
    DeviceStore.updateCurrentDevice(updatedDevice)
      .then(() => {
        showToast(DeviceToasts.deviceSettingsSaved);
      })
      .catch(() => {
        showToast(DeviceToasts.deviceSettingsSaveError);
      })
      .finally(() => setShowLoader(false));
  };

  const header = (
    <>
      <Button
        className={classNames("p-mr-2", {
          "p-button-danger": currentDevice.deviceTestMode,
          "p-button-outlined p-button-warning": !currentDevice.deviceTestMode,
        })}
        key="test-mode-button"
        style={{ height: 40 }}
        label={`Testmodus ${
          currentDevice.deviceTestMode ? "deaktivieren" : "aktivieren"
        }`}
        onClick={() =>
          DeviceStore.updateCurrentDevice({
            deviceTestMode: !currentDevice?.deviceTestMode,
          })
        }
      />
      <Button
        key="delete-button"
        icon="pi pi-trash"
        className="p-button-outlined p-button-danger"
        style={{ height: 40 }}
        label="Entfernen"
        onClick={() => setShowDeleteDialog(true)}
      />
    </>
  );

  const handleDeleteDeviceClicked = async () => {
    setShowLoader(true);
    const { deviceDeleted, deviceDeletionError } = DeviceToasts;
    const deletionSucceeded = await DeviceStore.deleteCurrentDevice();
    showToast(deletionSucceeded ? deviceDeleted : deviceDeletionError);
    setShowLoader(false);
  };

  return (
    <Card
      className={classNames("p-col", {
        "p-m-2": width < 580,
      })}
      footer={
        <SaveCancelFooter
          submitAction={useFormMethods.handleSubmit(updateCurrentDevice)}
          cancelAction={onCancelDeviceSettings}
        />
      }
    >
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar
        left={() => <h2>{currentDevice.name}</h2>}
        right={() => header}
      />
      <Divider />
      <h3>Allgemeine Informationen</h3>
      <div className="device-setting-section">
        <div className="device-setting-group">
          <div className="p-d-flex p-d-row p-align-center">
            <b className="userdata-p">Seriennummer:</b>
            <b className="p-ml-3">{currentDevice.serialNumber}</b>
          </div>
          <LocationFormField
            id={fieldIds.location}
            value={currentDevice.location}
            {...useFormMethods}
          />
        </div>
      </div>
      <Divider />
      <h3>Notfall-Szenario</h3>
      <div className="device-setting-section">
        {showEmergencyOptions && (
          <div className="device-setting-group">
            <b>Notrufleitstelle</b>
            <p>
              <i>
                Wählen Sie aus, welche Leitstelle Ihren Notruf empfangen soll.
                Markieren Sie das Gerät anschließend nach Möglichkeit mit einem
                der beigelegten Sticker, um Verwechslungen zu vermeiden.
              </i>
            </p>
            <EmergencyOptionsFormField
              id={fieldIds.emergencyCallOption}
              value={currentDevice.emergencyCall}
              emergencyOptions={emergencyOptions}
              {...useFormMethods}
            />
          </div>
        )}
        <div className="device-setting-group">
          <b>Notfallkontakte</b>
          <p>
            <i>
              Sie können Ihre Notfallkontakte im entsprechenden Reiter (siehe
              oben) verwalten.
            </i>
          </p>
          <div className="p-d-flex p-d-row p-align-center">
            <ContactsFormField
              id={fieldIds.contactsToNotify}
              contacts={contacts}
              value={ContactStore.getContactsById(
                currentDevice.contactIdsToNotify
              )}
              {...useFormMethods}
            />
            <Button
              type="button"
              id="update-address-btn"
              className="p-button-text p-field"
              icon="pi pi-plus"
              label="Kontakt"
              onClick={() => setAddContactDialogVisible(true)}
            />
          </div>
        </div>
        <div className="device-setting-group">
          <b>Zusätzliche Informationen</b>
          <p>
            <i>
              Beschreiben Sie hier das Szenario, für welchen Sie den Button
              nutzen möchten, z.B. "Überfall" oder "Medizinischer Notfall".
            </i>
          </p>
          <SilentMessageFormField
            id={fieldIds.incidentDetails}
            value={currentDevice.incidentDetails}
            {...useFormMethods}
          />
        </div>
        <AddContactDialog
          isVisible={addContactDialogVisible}
          closeModal={() => setAddContactDialogVisible(false)}
          showLoader={setShowLoader}
        />
        <ConfirmDialog
          header="Gerät entfernen?"
          content="Willst du das Gerät wirklich entfernen?"
          isVisible={showDeleteDialog}
          onHide={() => setShowDeleteDialog(false)}
          onClickYes={handleDeleteDeviceClicked}
        />
      </div>
    </Card>
  );
};

export default observer(DeviceSettingsCard);
