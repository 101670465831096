import { ContactType } from "../../api/firebase/types/emergencyContacts.types";

export const contactListTemplate = (contact: ContactType) => {
  return (
    <div className="contact-list-entry p-d-flex p-flex-row">
      <div className="p-mr-2">{contact.firstName}</div>
      <div>{contact.lastName}</div>
    </div>
  );
};

export const selectedContactTemplate = (contact: ContactType) => {
  if (!contact) return <div>Notfallkontakte wählen...</div>;
  return (
    <div className="selected-contact">
      <div className="p-mr-2">{contact.firstName}</div>
      <div>{contact.lastName}</div>
    </div>
  );
};

export default contactListTemplate;
