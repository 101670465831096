import { observer } from "mobx-react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoginView from "../pages/login/LoginView";
import SignupView from "../pages/signup/SignupView";
import { NavLink } from "./NavLinks";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../api/firebase";
import AppRouter from "./AppRouter";
import LoaderInCenter from "../components/loaders/LoaderInCenter";

const PageRouter = () => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) return <LoaderInCenter />;

  if (!user) {
    /** Nutzer ist nicht angemeldet */
    return (
      <Switch>
        <Route path={NavLink.SIGNIN} component={LoginView} exact />
        <Route path={NavLink.SIGNUP} component={SignupView} exact />
        <Redirect to={NavLink.SIGNIN} />
      </Switch>
    );
  }

  /**
   * Nutzer ist angemeldet und hat das Onboarding gemacht
   */
  return <AppRouter />;
};

export default observer(PageRouter);
