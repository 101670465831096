import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { Toolbar } from "primereact/toolbar";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UserDataType } from "../../api/firebase/types/userData.types";
import SaveCancelFooter from "../../components/footer/SaveCancelFooter";
import DiseaseForm from "./DiseaseForm";

export type DiseaseCardProps = {
  userData?: UserDataType;
};

const DiseaseCard: React.FC<DiseaseCardProps> = (props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const useFormMethods = useForm();

  const onFinishEditing = () => {
    setIsEditing(false);
    setShowLoader(false);
  };

  const headerDiseases = !isEditing && (
    <Button
      key="edit-button"
      icon="pi pi-pencil"
      className="p-button-text"
      style={{ height: 40 }}
      label="Bearbeiten"
      onClick={() => setIsEditing(true)}
    />
  );

  const noDataText = (
    <p>
      <i>Keine Informationen hinterlegt</i>
    </p>
  );

  return (
    <Card
      className="diseases-card p-col p-mb-2"
      footer={
        isEditing && (
          <SaveCancelFooter
            formId="disease-form"
            cancelAction={onFinishEditing}
          />
        )
      }
    >
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar
        left={() => <h2>Allgemeine Informationen für Rettungskräfte</h2>}
        right={() => headerDiseases}
      />
      <div className="p-mb-3">
        <i>
          Diese Informationen werden bei jeder Meldung aus diesem Account an die
          Rettungskräfte übertragen. Wählen Sie daher nur allgemeine
          Informationen, die dauerhaft unverändert bleiben und nicht von dem
          Szenario abhängen, für welches der Notruf-Button eingesetzt werden
          soll. Sie können diese Daten jederzeit bearbeiten oder zu einem
          späteren Zeitpunkt eintragen.
        </i>
      </div>
      <DiseaseForm
        useFormMethods={useFormMethods}
        diseases={props.userData?.diseases}
        hasHearingImpairment={props.userData?.hasHearingImpairment}
        agreedMedicalPolicy={props.userData?.agreedMedicalPolicy}
        agreedLegalDeviceUsage={props.userData?.agreedLegalDeviceUsage}
        editable={isEditing}
        onSubmit={onFinishEditing}
        onError={onFinishEditing}
        showLoader={() => setShowLoader(true)}
      />
      {!isEditing &&
        ((props.userData?.diseases && props.userData?.diseases.length === 0) ||
          !props.userData?.diseases) &&
        noDataText}
    </Card>
  );
};

export default observer(DiseaseCard);
