import React, { useContext, useEffect } from "react";
import { createTelekomUser } from "../../../../api/firebase/functionCallables/telekomCallables";
import { UserDataType } from "../../../../api/firebase/types/userData.types";
import ToastContext from "../../../../components/toast/ToastContext";
import ToastMessages from "../../../../toastMessages/signupToastMessages";
import DeviceView from "../../../device/DeviceView";

const DevicesStep: React.FC<{ userData?: UserDataType }> = ({ userData }) => {
  const showToast = useContext(ToastContext);
  const handleTelekomAccountCreation = async () => {
    if (!userData?.userId) return;

    const userCreated = await createTelekomUser(
      userData.userId,
      userData.firstName ? userData.firstName : "Vorname",
      userData.lastName ? userData.lastName : "Nachname"
    );
    if (!userCreated) showToast(ToastMessages.telekomUserCreationError);
  };

  useEffect(() => {
    handleTelekomAccountCreation();
  }, []);
  return <DeviceView isOnboarding />;
};

export default DevicesStep;
