import { InputNumber } from "primereact/inputnumber";
import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";

type ControlledIconFloatInputProps = FormBehaviourProps<number> &
  FormControlProps;

export default function PhoneFormField(props: ControlledIconFloatInputProps) {
  return (
    <FormControlled
      {...props}
      rules={{ required: true }}
      errorMessages={{
        required: "Telefonnummer fehlt",
      }}
    >
      {({ onChange, ...renderProps }) => (
        <InputNumber
          placeholder="z.B. 1731234567"
          useGrouping={false}
          onChange={(e) => {
            onChange && onChange(e.value);
          }}
          {...renderProps}
        />
      )}
    </FormControlled>
  );
}
