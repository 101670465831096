import classNames from "classnames";
import React from "react";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import { Container } from "../container";
import { Footer } from "../footer/Footer";
import { Header } from "../header";
import { NavigationProvider } from "./NavgationContext";

const PageLayout: React.FC = (props: React.PropsWithChildren<{}>) => {
  const [width] = useMediaQuery();
  return (
    <Container
      center={false}
      flexDirection="column"
      containerClassName="content-main"
    >
      <NavigationProvider>
        <Header />
        <div
          className={classNames("content", {
            small: width < screenSize.tablet,
            full: width >= screenSize.tablet,
          })}
        >
          {props.children}
        </div>
      </NavigationProvider>
      <Footer />
    </Container>
  );
};

export default PageLayout;
