import { Timestamp } from "firebase/firestore";
import { EmergencyCall } from "./incident.types";

export type Address = {
  street: string;
  houseNumber: number;
  postalCode: number;
  city: string;
};

export enum UserRole {
  ADMIN = "ADMIN",
  DESKTOP_CLIENT = "DESKTOP_CLIENT",
}

export type UserDataType = {
  userId: string;
  firstName: string;
  lastName: string;
  company?: string;
  birthDate: Timestamp;
  phone: string;
  diseases?: Array<string>;
  agreedMedicalPolicy?: boolean;
  agreedLegalDeviceUsage?: boolean;
  hasHearingImpairment?: boolean;
  hasSpeakingImpairment?: boolean;
  finishedOnboarding?: boolean;
  role: UserRole;
  callContactsByDefault?: boolean;
  mailContactsByDefault?: boolean;
  emergencyOptions: EmergencyCall[];
};
