import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";
import { Sidebar } from "primereact/sidebar";
import { Toolbar } from "primereact/toolbar";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { navItems } from "../../navigation/NavItems";
import NavigationContext from "../pageLayout/NavgationContext";
import AppLogo from "../../assets/images/Logo_InstantHelp_IconOnly_white.png";

const SidebarMenu: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { activeNavItem, setActiveNavItem } = useContext(NavigationContext);
  const history = useHistory();

  useEffect(() => {
    history.push(activeNavItem.path);
    setVisible(false);
  }, [activeNavItem]);

  return (
    <>
      <Button
        type="button"
        onClick={() => setVisible(true)}
        icon="pi pi-bars"
        className="p-button-outlined"
      />
      <Sidebar
        visible={visible}
        showCloseIcon={false}
        baseZIndex={1000000}
        onHide={() => setVisible(false)}
      >
        <div className="p-d-flex p-flex-column">
          <Toolbar
            className="header-banner"
            left={() => (
              <div className="p-d-flex p-flex-row p-ai-center">
                <img src={AppLogo} alt="logo" />
                <h1 className="title">InstantHelp</h1>
              </div>
            )}
            right={() => (
              <Button
                className="p-button-outlined p-button-primary"
                icon="pi pi-times"
                onClick={() => setVisible(false)}
              />
            )}
          />
          <ListBox
            value={activeNavItem}
            options={navItems}
            onChange={(e: any) => {
              e.value && setActiveNavItem(e.value);
            }}
            optionLabel="label"
          />
        </div>
      </Sidebar>
    </>
  );
};

export default SidebarMenu;
