import {
  addDoc,
  deleteDoc,
  doc,
  FirestoreError,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { createCollection, firestore } from "..";
import UserDataStore from "../../../store/UserDataStore";
import { CollectionName } from "../types/collections.types";
import { DeviceType } from "../types/devices.types";

const deviceCollection = createCollection<DeviceType>(CollectionName.DEVICES);

export const subscribeDevices = (
  onNewSnapshot: (devices: DeviceType[]) => void,
  onError: (err: FirestoreError) => void
) => {
  const user = UserDataStore.currentUser;
  if (!user?.userId) return;
  const deviceQuery = query(
    deviceCollection,
    where("userId", "==", user?.userId)
  );

  const unsubscribe = onSnapshot(
    deviceQuery,
    (querySnapshot) => {
      const devices = querySnapshot.docs.map((doc) => {
        const newDevice = { ...doc.data(), id: doc.id };
        return newDevice;
      });
      onNewSnapshot(devices);
    },
    (err) => onError(err)
  );
  return unsubscribe;
};

export const addDevice = async (device: Partial<DeviceType>) => {
  return await addDoc(deviceCollection, device);
};

export const updateDevice = async (
  deviceId: string,
  deviceParamsToUpdate: Partial<DeviceType>
) => {
  const deviceRef = doc(firestore, CollectionName.DEVICES, deviceId);
  return await setDoc(deviceRef, deviceParamsToUpdate, { merge: true });
};

export const deleteDevice = async (deviceId: string) => {
  const deviceRef = doc(firestore, CollectionName.DEVICES, deviceId);
  return await deleteDoc(deviceRef);
};

export default deviceCollection;
