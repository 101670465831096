import { observer } from "mobx-react";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { ContactType } from "../../api/firebase/types/emergencyContacts.types";
import ContactStore from "../../store/ContactStore";
import AddContactDialog from "./AddContactDialog";

export type EmergencyContactsToolbarTypes = {
  contactsToDelete: ContactType[] | undefined;
  onDelete: () => void;
  showLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

const EmergencyContactsToolbarRightElements: React.FC<
  EmergencyContactsToolbarTypes
> = (props) => {
  const [isDialogVisible, setDialogVisible] = useState<boolean>(false);

  const deleteContacts = async () => {
    props.showLoader(true);
    await Promise.all(
      props.contactsToDelete!.map(async (contact) => {
        await ContactStore.deleteContactById(contact.id);
      })
    );
    props.onDelete();
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Notfallkontakt erstellen"
          icon="pi pi-plus"
          className="p-button-text p-mr-2"
          onClick={() => setDialogVisible(true)}
        />
        <Button
          label="Löschen"
          icon="pi pi-trash"
          className="p-button-text p-button-danger"
          onClick={deleteContacts}
          disabled={
            !props.contactsToDelete || props.contactsToDelete.length === 0
          }
        />
      </>
    );
  };

  return (
    <>
      {rightToolbarTemplate()}
      <AddContactDialog
        isVisible={isDialogVisible}
        closeModal={() => setDialogVisible(false)}
        showLoader={props.showLoader}
      />
    </>
  );
};

export default observer(EmergencyContactsToolbarRightElements);
