import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

export type ConfirmDialogProps = {
  header: string;
  content: string;
  isVisible: boolean;
  onHide: () => void;
  onClickNo?: () => void;
  onClickYes: () => void;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const onClickNo = () => {
    props.onClickNo && props.onClickNo();
    props.onHide();
  };

  const onClickYes = () => {
    props.onClickYes();
    props.onHide();
  };

  const renderFooter = () => {
    return (
      <>
        <Button label="Ja" onClick={onClickYes} autoFocus />
        <Button label="Nein" onClick={onClickNo} className="p-button-text" />
      </>
    );
  };

  return (
    <Dialog
      header={props.header}
      visible={props.isVisible}
      modal
      style={{ width: "350px" }}
      footer={renderFooter()}
      onHide={props.onHide}
    >
      <div>
        <i
          className="pi pi-exclamation-triangle p-mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>{props.content}</span>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
