import React from "react";
import classNames from "classnames";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { countryCodes } from "../../../api/phoneRegistration/countryCodes";

export type CountryCodeOption = {
  name: string;
  code: string;
};

export type CountryCodeDropdownProps = Omit<DropdownProps, "onChange"> & {
  selectedCountry?: CountryCodeOption;
  error?: boolean;
  onChange: (selectedCountry: CountryCodeOption) => void;
  dropdownClassName?: string;
};

export const countryCodeTemplate = (option: CountryCodeOption, props?: any) => {
  if (option) {
    return (
      <div>
        {option.name} ({option.code})
      </div>
    );
  }
  return <div>{props.placeholder && props.placeholder}</div>;
};

const CountryCodeDropdown: React.FC<CountryCodeDropdownProps> = (props) => {
  const { onChange, ...otherProps } = props;
  return (
    <>
      <Dropdown
        className={classNames(props.dropdownClassName, {
          "p-invalid": props.error,
        })}
        value={props.selectedCountry}
        options={countryCodes}
        onChange={(e) => props.onChange(e.value)}
        optionLabel="name"
        itemTemplate={countryCodeTemplate}
        valueTemplate={countryCodeTemplate}
        filter
        filterBy="name"
        placeholder="Land wählen..."
        appendTo={document.body}
        {...otherProps}
      />
      {props.error && (
        <small id="email" className="p-error p-d-block form-error">
          Bitte Wählen Sie ein Land aus
        </small>
      )}
    </>
  );
};

export default CountryCodeDropdown;
