import { ListBox } from "primereact/listbox";

type LocationResultPreviewProps = {
  locationCandidates: any;
};

const LocationResultPreview: React.FC<LocationResultPreviewProps> = (props) => {
  const locationCandidatePreviewTemplate = (candidate: any) => {
    return (
      <li className="p-listbox-item">
        <p>{candidate.formatted_address}</p>
      </li>
    );
  };

  return (
    <>
      <p>Bitte bestätigen Sie Ihre Adresse:</p>

      <ListBox
        options={[props.locationCandidates]}
        itemTemplate={locationCandidatePreviewTemplate}
        optionLabel="Adresse"
        disabled
      />
    </>
  );
};

export default LocationResultPreview;
