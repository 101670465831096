import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText, InputTextProps } from "primereact/inputtext";
import React, { useState } from "react";

export type ButtonInputProps = Omit<InputTextProps, "onClick"> & {
  onClick: (inputText: string | undefined) => void;
  buttonIcon: string;
};

const ButtonInput: React.FC<ButtonInputProps> = (props) => {
  const [inputText, setInputText] = useState<string | undefined>(undefined);

  return (
    <div className={classNames("p-inputgroup", props.className)}>
      <InputText
        placeholder={props.placeholder}
        value={inputText}
        onChange={(e) => setInputText(e.currentTarget.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            props.onClick(inputText);
            setInputText("");
            event.preventDefault();
          }
        }}
      />
      <Button
        type="button"
        icon={`pi ${props.buttonIcon}`}
        onClick={() => {
          props.onClick(inputText);
          setInputText("");
        }}
      />
    </div>
  );
};

export default ButtonInput;
