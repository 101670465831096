import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import { Checkbox } from "primereact/checkbox";
import { links } from "../../../config";
import classNames from "classnames";
import { Divider } from "primereact/divider";

type ControlledIconFloatInputProps = FormBehaviourProps<boolean> &
  FormControlProps;

export default function LegalDeviceUsageFormField(
  props: ControlledIconFloatInputProps
) {
  return (
    <FormControlled
      {...props}
      rules={{
        required: true,
      }}
      errorMessages={{
        required:
          "Bitte stimmen Sie den Hinweisen zur versehentlichen Auslösung eines Notrufs zu",
      }}
    >
      {({ value, onChange, ...renderProps }) => (
        <div className="p-m-3">
          <Divider />
          <Checkbox
            className={classNames("p-mt-3", {
              "p-invalid": renderProps.error,
            })}
            checked={value}
            onChange={(e) => onChange(e.checked)}
            {...renderProps}
          />
          <label className="p-ml-2">
            Ich habe die{" "}
            <a href={links.legalDeviceUsage} target="_blank">
              Hinweise zur versehentlichen Auslösung eines Notrufs
            </a>{" "}
            zur Kenntnis genommen.
          </label>
        </div>
      )}
    </FormControlled>
  );
}
