import React, { useContext } from "react";
import AppLogo from "../../assets/images/Logo_InstantHelp_IconOnly_white.png";
import { UserContext } from "../../store/UserProvider";
import { Container } from "../container";
import HeaderToolbar from "./HeaderToolbar";

export type TabItem = {
  label: string;
  icon: string;
  path: string;
  command: (event: any) => void;
};

export const Header: React.FC = () => {
  const user = useContext(UserContext);

  return (
    <Container flexDirection="column" containerClassName="header">
      <Container flexDirection="row" containerClassName="header-banner">
        <img src={AppLogo} alt="logo" />
        <h1 className="title">InstantHelp</h1>
      </Container>
      <HeaderToolbar user={user} />
    </Container>
  );
};
export default Header;
