import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import PhoneAuth from "../../userData/phoneAuth/PhoneAuth";

type PhoneAuthDialogButtonProps = {
  phone?: string;
  isEditing: boolean;
};

const PhoneAuthDialogButton: React.FC<PhoneAuthDialogButtonProps> = ({
  phone,
  isEditing,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  return (
    <div className="p-d-flex p-align-center">
      <p className="userdata-p">Telefonnummer:</p>
      <b className="p-ml-3">{phone}</b>
      {isEditing && (
        <Button
          type="button"
          key="edit-button"
          className="p-button-text"
          label="Ändern"
          onClick={() => setDialogVisible(true)}
        />
      )}
      <Dialog
        header="Neue Telefonnummer Authentifizieren"
        visible={dialogVisible}
        modal
        onHide={() => setDialogVisible(false)}
      >
        <PhoneAuth verifiedPhone={phone} />
      </Dialog>
    </div>
  );
};

export default PhoneAuthDialogButton;
