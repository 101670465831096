import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React from "react";
import { ContactType } from "../../api/firebase/types/emergencyContacts.types";
import ContactStore from "../../store/ContactStore";
type AlarmItemProps = {
  contact: ContactType;
};

const ContactItem: React.FC<AlarmItemProps> = ({ contact }) => {
  return (
    <Card
      className="p-col-12 contact-item"
      subTitle={`${contact.firstName} ${contact.lastName}`}
      footer={
        <div className="p-d-flex">
          <Button
            type="button"
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger"
            onClick={() => ContactStore.deleteContactById(contact.id)}
          />
        </div>
      }
    >
      <div className="p-d-flex p-jc-between p-mb-2">
        <div>
          <b>Telefonnummer</b>
        </div>
        <div>{contact.phone}</div>
      </div>
      <div className="p-d-flex p-jc-between p-mb-1">
        <div>
          <b>E-Mail</b>
        </div>
        <div>{contact.email || <i>Nicht angegeben</i>}</div>
      </div>
    </Card>
  );
};

export default ContactItem;
