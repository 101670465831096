import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { useState } from "react";
import { ContactType } from "../../api/firebase/types/emergencyContacts.types";
import AddContactDialog from "./AddContactDialog";
import ContactItem from "./ContactItem";

type ContactItemViewProps = {
  contacts?: ContactType[];
};

const ContactItemView: React.FC<ContactItemViewProps> = (props) => {
  const [addContactDialogVisible, setAddContactDialogVisible] = useState(false);

  const renderContent = () => {
    if (!props.contacts || !props.contacts?.length)
      return (
        <p>
          <i>Keine Notfallkontakte eingerichtet</i>
        </p>
      );
    return (
      <>
        {props.contacts?.map((contact) => (
          <ContactItem key={contact.id} contact={contact} />
        ))}
      </>
    );
  };

  return (
    <div className="contact-item-view">
      <Toolbar
        left={() => <h2 className="p-ml-3">Notfallkontakte</h2>}
        right={() => (
          <Button
            icon="pi pi-plus"
            className="p-mx-3"
            onClick={() => setAddContactDialogVisible(true)}
          />
        )}
      />
      {renderContent()}
      <AddContactDialog
        isVisible={addContactDialogVisible}
        closeModal={() => setAddContactDialogVisible(false)}
        showLoader={() => {}}
      />
    </div>
  );
};

export default ContactItemView;
