import { ToastMessageType } from "../components/toast/ToastContext";

export const DeviceToasts: { [key: string]: ToastMessageType } = {
  deviceCreated: {
    severity: "success",
    summary: "Geräteregistrierung erfolgreich",
    detail: "Das Gerät wurde erfolgreich registriert",
  },
  deviceCreationError: {
    severity: "error",
    summary: "Geräteregistrierung fehlgeschlagen",
    detail: "Die Geräteregistrierung konnte nicht durchgeführt werden",
  },
  deviceDeleted: {
    severity: "success",
    summary: "Gerät entfernt",
    detail: "Das Gerät wurde erfolgreich entfernt",
  },
  deviceDeletionError: {
    severity: "error",
    summary: "Geräteentfernung fehlgeschlagen",
    detail: "Das Gerät konnte nicht entfernt werden",
  },
  deviceSettingsSaved: {
    severity: "success",
    summary: "Einstellungen gespeichert",
    detail: "Die Geräteeinstellungen wurden erfolgreich gespeichert",
  },
  deviceSettingsSaveError: {
    severity: "error",
    summary: "Speichern fehlgeschlagen",
    detail: "Die Geräteeinstellungen konnten nicht gespeichert werden",
  },
};
