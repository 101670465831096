import React from "react";
import { links } from "../../config";

export const Footer: React.FC = () => {
  return (
    <div className="ih-footer">
      <small>
        <a href={links.termsOfUse} target="_blank">
          AGB
        </a>
      </small>
      <small>
        <a href={links.dataPolicy} target="_blank">
          Datenschutzbestimmungen
        </a>
      </small>
      <small>
        <a href={links.dataPolicyMedical} target="_blank">
          Hinweise zur Verarbeitung med. Daten
        </a>
      </small>
      <small>
        <a href={links.legalDeviceUsage}>
          Hinweise zur Auslösung eines Notrufs
        </a>
      </small>
    </div>
  );
};
