import { makeAutoObservable, runInAction } from "mobx";
import SessionStore from "./SessionStore";
import { ContactType } from "../api/firebase/types/emergencyContacts.types";
import DeviceStore from "./DeviceStore";
import {
  addContact,
  deleteContact,
  subscribeContacts,
  updateContact,
} from "../api/firebase/collections/emergencyContactCollection";
import { Unsubscribe } from "firebase/auth";
import { FirebaseError } from "firebase/app";

class ContactStore {
  error?: any = null;
  isPending: boolean = false;
  contacts: ContactType[] = [];
  unsubscribeContacts?: Unsubscribe;

  constructor() {
    makeAutoObservable(this);
  }

  subscribeContacts() {
    const onNewSnapshot = (contacts: ContactType[]) => {
      console.log("new contact snapshot");
      runInAction(() => {
        this.contacts = contacts;
      });
    };
    const onError = (error: FirebaseError) => {
      console.error(error);
      runInAction(() => {
        this.error = error;
      });
    };
    const unsubscribe = subscribeContacts(onNewSnapshot, onError);
    runInAction(() => {
      this.unsubscribeContacts = unsubscribe;
    });
  }

  addContact = async (emergencyContact: ContactType) => {
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      const newContact: ContactType = {
        ...emergencyContact,
        userId: SessionStore.user!.uid,
      };
      await addContact(newContact);
      runInAction(() => {
        this.isPending = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
    }
  };

  updateContact = async (updatedContact: ContactType) => {
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      await updateContact(updatedContact);
      runInAction(() => {
        this.isPending = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
    }
  };

  deleteContactById = async (contactId?: string) => {
    if (!contactId) {
      console.error("contact could not be deleted. DocumentId is not defined");
      return;
    }
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      await deleteContact(contactId);
      await DeviceStore.deleteContactsFromDevices([contactId]);
      runInAction(() => {
        this.isPending = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
    }
  };

  getContactsById = (contactIds?: string[]) => {
    if (!contactIds) return [];
    const contactsToNotify = this.contacts?.filter((contact) => {
      return contactIds.includes(contact.id);
    });
    return contactsToNotify;
  };
}

export default new ContactStore();
