import { ToastMessageType } from "../components/toast/ToastContext";
import config from "./../config";
const ToastMessages: { [key: string]: ToastMessageType } = {
  mailAlreadyUsedError: {
    severity: "error",
    summary: "Registrierung fehlgeschlagen",
    detail:
      "Die Email Adresse wird bereits von einem anderen Account verwendet",
  },
  missingComplianceError: {
    severity: "error",
    summary: "Zustimmung erforderlich",
    detail:
      "Bitte stimme unseren AGB und Datenschutzbestimmungen vor der Registrierung zu",
  },
  telekomUserCreationError: {
    severity: "error",
    summary: "Einrichtung nicht abgeschlossen",
    detail: `Es ist ein Problem bei der Einrichtung aufgetreten, bitte wende dich an den Support unter ${config.support_mail}.`,
    sticky: true,
  },
};

export default ToastMessages;
