import { InputText } from "primereact/inputtext";
import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps;

export default function NameFormField(props: ControlledIconFloatInputProps) {
  return (
    <FormControlled
      {...props}
      rules={{ required: true, minLength: 2 }}
      errorMessages={{
        required: "Angabe fehlt",
        minLength: "Name zu kurz",
      }}
    >
      {(renderProps) => <InputText {...renderProps} />}
    </FormControlled>
  );
}
