import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import { Checkbox } from "primereact/checkbox";
import { links } from "../../../config";
import classNames from "classnames";

type ControlledIconFloatInputProps = FormBehaviourProps<boolean> &
  FormControlProps & {
    googleComplianceError: boolean;
    setGoogleComplianceError: React.Dispatch<React.SetStateAction<boolean>>;
  };

export default function AgreeTermsFormField(
  props: ControlledIconFloatInputProps
) {
  const {googleComplianceError, ...restProps} = props;
  return (
    <FormControlled
      {...restProps}
      rules={{
        validate: {
          compliance: (value) => value === true
        },
      }}
      errorMessages={{
        compliance: "Bitte stimmen Sie den AGB und Datenschutzbestimmungen zu"
      }}
    >
      {({ value, onChange, ...renderProps }) => (
        <div>
          <Checkbox
             className={classNames({
               "p-invalid":
                 renderProps.error || googleComplianceError
             })}
            checked={value}
            onChange={(e) => {onChange(e.checked); props.setGoogleComplianceError(false);}}
            {...renderProps}
          />
          <label className="p-ml-2">
            Ich habe die{" "}
            <a href={links.termsOfUse} target="_blank">
              AGB und Datenschutzbestimmungen
            </a>{" "}
            zur Kenntnis genommen und akzeptiere sie.
          </label>
        </div>
      )}
    </FormControlled>
  );
}
