import { doc, getDoc, setDoc } from "firebase/firestore";
import { createCollection, firestore } from "..";
import { CollectionName } from "../types/collections.types";
import { UserDataType } from "../types/userData.types";

export const userDataCollection = createCollection<UserDataType>(
  CollectionName.USER_DATA
);

export const getCurrentUser = async (userId?: string) => {
  validateUserId({ userId });
  console.log("getting current user");
  try {
    const userRef = doc(firestore, CollectionName.USER_DATA, userId!);
    const userDoc = await getDoc(userRef);
    return { ...userDoc.data(), userId: userDoc.id } as UserDataType;
  } catch (e) {
    console.error(e);
  }
};

export const addUserData = async (userData: Partial<UserDataType>) => {
  validateUserId(userData);
  const userRef = doc(firestore, CollectionName.USER_DATA, userData.userId!);
  await setDoc(userRef, userData).catch((err) => console.log(err));
};

export const updateUserData = async (userData: Partial<UserDataType>) => {
  validateUserId(userData);
  const userRef = doc(firestore, CollectionName.USER_DATA, userData.userId!);
  await setDoc(userRef, userData, { merge: true }).catch((err) =>
    console.log(err)
  );
};

const validateUserId = (userData: Partial<UserDataType>) => {
  if (!userData.userId) {
    console.error("addUserData error: no userId provided");
    return;
  }
};

export default userDataCollection;
