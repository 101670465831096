import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import UserDataStore from "../../store/UserDataStore";
import DiseaseCard from "./DiseaseCard";
import UserDataCard from "./UserDataCard";

const UserDataView: React.FC = () => {
  const [width] = useMediaQuery();

  const userData = UserDataStore.currentUser;
  return (
    <div
      className={classNames("userdataview-container p-grid", {
        "p-jc-center": width < screenSize.tablet,
        "p-px-3": width >= screenSize.tablet,
      })}
    >
      <UserDataCard userData={userData} />
      <DiseaseCard userData={userData} />
    </div>
  );
};

export default observer(UserDataView);
