import { observer } from "mobx-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressBar } from "primereact/progressbar";
import { Toolbar } from "primereact/toolbar";
import React, { useState } from "react";
import { IncidentWithDevice } from "../../api/firebase/firestoreDataTypes/incidentData.types";
import { IncidentType } from "../../api/firebase/types/incident.types";
import BadgeSelectButton from "../../components/buttons/BadgeSelectButton";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import IncidentStore from "../../store/IncidentStore";
import AlarmDetailModal from "./AlarmDetailModal";
import {
  alarmActionTemplate,
  alarmActionTemplateTestIncident,
  alarmCategoryTemplate,
  alarmDateTemplate,
  alarmDeviceTemplate,
  alarmStatusTemplate,
} from "./AlarmTableTemplates";

type AlarmTableViewProps = {
  realIncidents?: IncidentWithDevice[];
  testIncidents?: IncidentWithDevice[];
};

const AlarmTableView: React.FC<AlarmTableViewProps> = (props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [selectedIncident, setSelectedIncident] =
    useState<IncidentWithDevice>();
  const [showTestAlerts, setShowTestAlerts] = useState<boolean>(false);
  const [incidentToDelete, setIncidentToDelete] = useState<IncidentType>();

  const onConfirmDeleteIncident = async () => {
    setShowLoader(true);
    await IncidentStore.deleteIncidentById(incidentToDelete?._id);
    setIncidentToDelete(undefined);
    setShowLoader(false);
  };

  const onClickDeleteButton = (incident: IncidentWithDevice) =>
    setIncidentToDelete(incident);
  const onClickDetailButton = (incident: IncidentWithDevice) =>
    setSelectedIncident(incident);

  const actionTemplateTestIncident = alarmActionTemplateTestIncident(
    onClickDeleteButton,
    onClickDetailButton
  );
  const actionTemplate = alarmActionTemplate(onClickDetailButton);
  const alarmsReceived =
    (props.realIncidents && props.realIncidents?.length > 0) ||
    (props.testIncidents && props.testIncidents?.length > 0);

  return (
    <div className="p-fluid">
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar
        left={() => <h2>{showTestAlerts ? "Test-Meldungen" : "Meldungen"}</h2>}
        right={() =>
          alarmsReceived && (
            <>
              <BadgeSelectButton
                className="p-mr-2"
                label="Meldungen"
                onClick={() => setShowTestAlerts(false)}
                isActive={!showTestAlerts}
                numOfElements={props.realIncidents?.length || 0}
              ></BadgeSelectButton>
              <BadgeSelectButton
                activeButtonColor="p-button-warning"
                label="Testmeldungen"
                onClick={() => setShowTestAlerts(true)}
                isActive={showTestAlerts}
                numOfElements={props.testIncidents?.length || 0}
              ></BadgeSelectButton>
            </>
          )
        }
      />
      {alarmsReceived ? (
        <DataTable
          dataKey="id"
          className={showTestAlerts ? "test-mode" : ""}
          value={showTestAlerts ? props.testIncidents : props.realIncidents}
        >
          <Column header="Kategorie" body={alarmCategoryTemplate} />
          <Column header="Datum" body={alarmDateTemplate} />
          <Column header="Status" body={alarmStatusTemplate} />
          <Column header="Gerät" body={alarmDeviceTemplate} />
          <Column
            body={showTestAlerts ? actionTemplateTestIncident : actionTemplate}
            headerStyle={{ width: "10em" }}
          ></Column>
        </DataTable>
      ) : (
        <p>
          <i>Bisher sind keine Meldungen abgesetzt worden</i>
        </p>
      )}
      <AlarmDetailModal
        selectedIncident={selectedIncident}
        setSelectedIncident={setSelectedIncident}
      />
      <ConfirmDialog
        header="Testmeldung entfernen?"
        content="Möchten Sie die Testmeldung wirklich entfernen?"
        isVisible={incidentToDelete !== undefined}
        onHide={() => setIncidentToDelete(undefined)}
        onClickYes={onConfirmDeleteIncident}
      />
    </div>
  );
};
export default observer(AlarmTableView);
