import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { countryCodes } from "../../api/phoneRegistration/countryCodes";
import ContactStore from "../../store/ContactStore";
import CountryCodeDropdown, {
  CountryCodeOption,
} from "../userData/phoneAuth/CountryCodeDropdown";
import MailFormField from "./formFields/MailFormField";
import NameFormField from "./formFields/NameFormField";
import PhoneFormField from "./formFields/PhoneFormField";

export type AddContactDialogType = {
  isVisible: boolean;
  closeModal: () => void;
  showLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddContactDialog: React.FC<AddContactDialogType> = (props) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCodeOption>();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [noCountryError, setNoCountryError] = useState<boolean>(false);

  useEffect(() => {
    const initialCountry = countryCodes.find(
      (country) => country.name === "Germany"
    );
    setSelectedCountry(initialCountry);
  }, []);

  const onClose = () => {
    props.closeModal();
  };

  const onSave = async (data: any) => {
    props.showLoader(true);
    setIsButtonDisabled(true);
    if (selectedCountry?.code === undefined) {
      alert("Fehlerhafte Telefonvorwahl");
      return;
    }
    await ContactStore.addContact({
      ...data,
      phone: selectedCountry!.code + data.phone,
    });
    props.showLoader(false);
    props.closeModal();
  };

  const handleCountryCodeChange = (selectedCountry: CountryCodeOption) => {
    selectedCountry && setNoCountryError(false);
    setSelectedCountry(selectedCountry);
  };

  const fieldIds = {
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    phone: "phone",
  };
  const defaultValues = {
    [fieldIds.firstName]: undefined,
    [fieldIds.lastName]: undefined,
    [fieldIds.email]: undefined,
    [fieldIds.phone]: undefined,
  };

  const useFormMethods = useForm({
    defaultValues,
  });

  const dialogFooter = () => {
    return (
      <>
        <Button
          disabled={isButtonDisabled}
          label="Speichern"
          onClick={useFormMethods.handleSubmit(onSave)}
        />
        <Button
          label="Abbrechen"
          disabled={isButtonDisabled}
          className="p-button-secondary p-button-text"
          onClick={props.closeModal}
        />
      </>
    );
  };

  return (
    <Dialog
      visible={props.isVisible}
      header="Notfallkontakt hinzufügen"
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={onClose}
      onShow={() => setIsButtonDisabled(false)}
      id="add-contact-dialog"
    >
      <form autoComplete="off" onSubmit={useFormMethods.handleSubmit(onSave)}>
        <div className="p-formgrid p-grid">
          <div className="p-field p-col-6">
            <label htmlFor="firstName">Vorname</label>
            <NameFormField id={fieldIds.firstName} {...useFormMethods} />
          </div>

          <div className="p-field p-col-6">
            <label htmlFor="lastName">Nachname</label>
            <NameFormField id={fieldIds.lastName} {...useFormMethods} />
          </div>

          <div className="p-field p-col-12">
            <label htmlFor="email">E-Mail</label>
            <MailFormField id={fieldIds.email} {...useFormMethods} />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-field p-col-5">
            <label htmlFor="phone">Land</label>
            <CountryCodeDropdown
              selectedCountry={selectedCountry}
              onChange={handleCountryCodeChange}
              error={noCountryError}
            />
          </div>
          <div className="p-field p-col-7">
            <label htmlFor="phone">Telefonnummer</label>
            <PhoneFormField id={fieldIds.phone} {...useFormMethods} />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default observer(AddContactDialog);
