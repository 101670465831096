import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import React from "react";
import { IncidentWithDevice } from "../../api/firebase/firestoreDataTypes/incidentData.types";
import {
  EmergencyCallGerman,
  IncidentStatus,
  IncidentStatusGerman,
  IncidentType,
} from "../../api/firebase/types/incident.types";
import { getMapsLink } from "../../api/places";

const AlarmDetailModal: React.FC<{
  selectedIncident?: IncidentWithDevice;
  setSelectedIncident: (incident?: IncidentWithDevice) => void;
}> = ({ selectedIncident, setSelectedIncident }) => {
  const dialogFooter = () => {
    return (
      <>
        <Button label="Schließen" onClick={closeModal} />
      </>
    );
  };
  const closeModal = () => setSelectedIncident(undefined);
  const link = getMapsLink(selectedIncident?.location.geolocation);

  const getDeviceAddress = (incident: IncidentWithDevice) => {
    if (!incident.deviceId) return incident.location.geolocation.toString();
    if (!incident.device) return incident.location.geolocation.toString();
    return incident.device.location?.name;
  };

  const templateStatus = (incident: IncidentType) => {
    const status: IncidentStatus = (incident.psap_reporting_status ||
      incident.status) as IncidentStatus;

    const statusText = IncidentStatusGerman[status] || "Unbekannt";

    let statusClass = "error pi-exclamation-circle";
    if (statusText === "Übermittelt") statusClass = "success pi-check-circle";
    if (statusText === "In Übermittlung") statusClass = "pending pi-reply";

    return (
      <div style={{ width: "100%" }} className={`p-input-icon-left `}>
        <i className={`pi status-icon-${statusClass}`} />
        <InputText style={{ width: "100%" }} disabled value={statusText} />
      </div>
    );
  };

  const getCategory = (incident: IncidentType) => {
    if (!incident.emergencyCall) return "Unbekannt";
    return EmergencyCallGerman[incident.emergencyCall];
  };

  return (
    <Dialog
      visible={selectedIncident !== undefined}
      header={`${
        selectedIncident?.deviceTestMode ? "Test: " : ""
      }Alarm Details`}
      modal
      id="alarm-table-modal"
      footer={dialogFooter}
      onHide={closeModal}
      style={{ width: "800px" }}
    >
      {selectedIncident === undefined ? (
        <p>no data</p>
      ) : (
        <>
          <div className="p-formgrid p-grid">
            <div className="p-field p-md-6 p-sm-12">
              <label className="p-mr-2">
                <b>Kategorie</b>
              </label>
              <div className="p-inputgroup">
                <InputText value={getCategory(selectedIncident)} disabled />
              </div>
            </div>
            <div className="p-field p-md-6 p-sm-12">
              <label className="p-mr-2">
                <b>Datum</b>
              </label>

              <div className="p-inputgroup">
                <InputText
                  id="serialNumber"
                  value={`${moment(selectedIncident.created?.toDate()).format(
                    "dddd, DD.MM.YYYY - HH:mm"
                  )} Uhr`}
                  disabled
                />
              </div>
            </div>
            <div className="p-field p-md-6 p-sm-12">
              <label className="p-mr-2">
                <b>Status</b>
              </label>
              {templateStatus(selectedIncident)}
            </div>
            <div className="p-field p-md-6 p-sm-12">
              <label className="p-mr-2">
                <b>Adresse</b>
              </label>
              <div className="p-inputgroup">
                <InputText
                  value={getDeviceAddress(selectedIncident)}
                  disabled
                />
                <Button
                  icon="pi pi-fw pi-map-marker"
                  className="p-button-primary"
                  href={link}
                  onClick={() => window.open(link)}
                />
              </div>
            </div>

            {selectedIncident.silent_message && (
              <div className="p-field p-md-12 p-sm-12">
                <label className="p-mr-2">
                  <b>Zusatzinformationen</b>
                </label>
                <div className="p-inputgroup">
                  <InputText disabled value={selectedIncident.silent_message} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Dialog>
  );
};

export default observer(AlarmDetailModal);
