import { FirebaseError } from "firebase/app";
import { Unsubscribe } from "firebase/auth";
import { makeAutoObservable, runInAction } from "mobx";
import {
  deleteIncident,
  subscribeIncidents,
} from "../api/firebase/collections/incidentCollection";
import { IncidentType } from "../api/firebase/types/incident.types";

class IncidentStore {
  error?: any = null;
  isPending: boolean = false;
  incidents: IncidentType[] = [];
  unsubscribeIncidents?: Unsubscribe;

  constructor() {
    makeAutoObservable(this);
  }

  subscribeIncidents() {
    const onNewSnapshot = (incidents: IncidentType[]) => {
      console.log("new incident snapshot");
      runInAction(() => {
        this.incidents = incidents;
      });
    };
    const onError = (error: FirebaseError) => {
      console.error(error);
      runInAction(() => {
        this.error = error;
      });
    };
    const unsubscribe = subscribeIncidents(onNewSnapshot, onError);
    runInAction(() => {
      this.unsubscribeIncidents = unsubscribe;
    });
  }

  deleteIncidentById = async (incidentId?: string) => {
    if (!incidentId) return;
    runInAction(() => {
      this.isPending = true;
    });
    try {
      deleteIncident(incidentId);
      runInAction(() => {
        this.isPending = false;
        this.error = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
    }
  };
}

export default new IncidentStore();
