import { useState } from "react";
import { IncidentWithDevice } from "../../api/firebase/firestoreDataTypes/incidentData.types";
import AlarmItem from "./AlarmItem";
import { ToggleButton } from "primereact/togglebutton";
import { Toolbar } from "primereact/toolbar";

type AlarmItemViewProps = {
  realIncidents?: IncidentWithDevice[];
  testIncidents?: IncidentWithDevice[];
};

const AlarmItemView: React.FC<AlarmItemViewProps> = (props) => {
  const [showTestAlerts, setShowTestAlerts] = useState<boolean>(false);

  return (
    <div className="p-flex-column alarm-item-view">
      <Toolbar
        left={() => (
          <h2 className="p-ml-3">
            {showTestAlerts ? "Test-Meldungen" : "Meldungen"}
          </h2>
        )}
        right={() => (
          <ToggleButton
            className="p-mr-3"
            checked={showTestAlerts}
            onChange={(e: any) => setShowTestAlerts(e.value)}
            onLabel="Testmeldungen"
            offLabel="Meldungen"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
          />
        )}
      />
      <div className="p-flex-row p-grid p-jc-center">
        {(showTestAlerts ? props.testIncidents : props.realIncidents)?.map(
          (incident) => {
            return <AlarmItem key={incident.id} incident={incident} />;
          }
        )}
      </div>
    </div>
  );
};

export default AlarmItemView;
