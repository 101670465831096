import React from "react";
import moment from "moment";
import {
  EmergencyCallGerman,
  IncidentStatus,
  IncidentStatusGerman,
  IncidentType,
} from "../../api/firebase/types/incident.types";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { IncidentWithDevice } from "../../api/firebase/firestoreDataTypes/incidentData.types";

export const alarmCategoryTemplate = (incident: IncidentType) => {
  const category = incident.emergencyCall
    ? EmergencyCallGerman[incident.emergencyCall]
    : "Unbekannt";
  return (
    <div>
      {incident.deviceTestMode && (
        <Badge className="p-mr-2" severity="warning" value="TEST" />
      )}
      {category}
    </div>
  );
};

export const alarmDateTemplate = (incident: IncidentType) => {
  return incident.created
    ? `${moment(incident.created?.toDate()).format("DD.MM.YYYY,  HH:mm")} Uhr`
    : "Unbekannt";
};

export const alarmStatusTemplate = (incident: IncidentType) => {
  const status: IncidentStatus = (incident.psap_reporting_status
    ? incident.psap_reporting_status
    : incident.status) as IncidentStatus;

  let statusText = incident.status ? IncidentStatusGerman[status] : "Unbekannt";

  let statusClass = "error pi-exclamation-circle";
  if (statusText === "Übermittelt") statusClass = "success pi-check-circle";
  if (statusText === "In Übermittlung") statusClass = "pending pi-reply";

  return (
    <>
      <span
        className={`pi status-icon-${statusClass} status-icon-padding`}
      ></span>
      <span>{statusText}</span>
    </>
  );
};

export const alarmDeviceTemplate = (incident: IncidentWithDevice) => {
  return incident.device?.name || "Unbekannt";
};

export const alarmActionTemplateTestIncident = (
  handleDeleteButtonClick: (incident: IncidentWithDevice) => void,
  handleDetailButtonClick: (incident: IncidentWithDevice) => void
) => {
  return (incident: IncidentWithDevice) => (
    <div className="p-d-flex p-d-row">
      {detailButton(() => handleDetailButtonClick(incident))}
      {deleteButton(() => handleDeleteButtonClick(incident))}
    </div>
  );
};

export const alarmActionTemplate = (
  handleDetailButtonClick: (incident: IncidentWithDevice) => void
) => {
  return (incident: IncidentWithDevice) => (
    <div className="p-d-flex p-d-row">
      {detailButton(() => handleDetailButtonClick(incident))}
    </div>
  );
};

const detailButton = (clicked: () => void) => (
  <Button
    type="button"
    icon="pi pi-file"
    className="p-button-rounded p-mr-2"
    onClick={clicked}
  />
);

const deleteButton = (clicked: () => void) => (
  <Button
    type="button"
    icon="pi pi-trash"
    className="p-button-rounded p-button-danger"
    onClick={clicked}
  />
);
