import { Button } from "primereact/button";
import React from "react";

type DeleteButtonListEntryProps = {
  label: string;
  editable?: boolean;
  handleDelete: (label: string) => void;
};

const DeleteButtonListEntry: React.FC<DeleteButtonListEntryProps> = ({
  label,
  editable,
  handleDelete,
}) => {
  return (
    <div className="p-inputgroup p-d-flex p-align-center p-jc-between">
      <div>{label}</div>
      {editable !== false && (
        <Button
          icon="pi pi-trash"
          className="p-button-danger p-button-rounded p-button-text p-button-sm"
          onClick={() => handleDelete(label)}
        />
      )}
    </div>
  );
};

function deleteButtonListTemplate(
  handleDelete: (label: string) => void,
  editable?: boolean
) {
  return (label: string) => (
    <DeleteButtonListEntry
      label={label}
      editable={editable}
      handleDelete={handleDelete}
    />
  );
}

export default deleteButtonListTemplate;
