import classNames from "classnames";
import moment from "moment";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Knob } from "primereact/knob";
import { ProgressBar } from "primereact/progressbar";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getBatteryByDeviceId } from "../../api/firebase/functionCallables/telekomCallables";
import {
  DeviceBatteryType,
  DeviceType,
} from "../../api/firebase/types/devices.types";
import { EmergencyCallGerman } from "../../api/firebase/types/incident.types";
import TooltipButton from "../../components/buttons/TooltipButton";
import NavigationContext from "../../components/pageLayout/NavgationContext";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import { getNavItemByPath } from "../../navigation/NavItems";
import { NavLink } from "../../navigation/NavLinks";
import DeviceStore from "../../store/DeviceStore";

type HomeViewDeviceCardProps = {
  device: DeviceType;
  deviceDocId?: string;
};

const HomeViewDeviceCard: React.FC<HomeViewDeviceCardProps> = ({
  device,
  deviceDocId,
}) => {
  const history = useHistory();

  const { setActiveNavItem } = useContext(NavigationContext);
  const [width] = useMediaQuery();

  const deviceInfoEntries: Record<string, any> = {
    "SOS Button: ": EmergencyCallGerman[device.emergencyCall],
    "Notfallkontakte: ": device.contactIdsToNotify?.length,
    "Testmodus: ": device.deviceTestMode ? "Ein" : "Aus",
  };

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [battery, setBattery] = useState<DeviceBatteryType>();

  useEffect(() => {
    checkBattery();
  }, []);
  const checkBattery = async () => {
    setShowLoader(true);
    const batteryMeasurement = await getBatteryByDeviceId(device.deviceId);
    setShowLoader(false);
    setBattery(batteryMeasurement);
  };

  const getColor = (battery: number) => {
    if (battery > 70) return "green";
    if (battery > 40) return "orange";
    return "red";
  };

  const batteryToolTip = (
    <TooltipButton
      id="tooltip-btn-no-battery"
      tooltip="Der Akkustand wird beim Absetzen eines Notrufes ermittelt. Um den aktuellen Akkustand zu ermitteln, können Sie jederzeit einen Test-Notruf im Testmodus absetzen."
    />
  );
  const getBatteryPreview = (battery: DeviceBatteryType) => (
    <>
      <Knob
        valueTemplate={`${battery.value}%`}
        valueColor={getColor(parseInt(battery.value))}
        value={battery.value}
      />
      <small>Akkustand ({battery.date.from(moment())})</small>
      {batteryToolTip}
    </>
  );
  const getBatteryUnknownPreview = (
    <>
      <Knob valueTemplate="?" valueColor="red" value="0" />
      <div>
        <small>Akkustand unbekannt</small>
        {batteryToolTip}
      </div>
    </>
  );

  return (
    <Card
      subTitle={device.name}
      className={classNames("homeview-device-card p-col p-mb-2", {
        "p-mr-2": width >= screenSize.phone,
      })}
    >
      {showLoader && <ProgressBar mode="indeterminate" />}
      <div className="battery-info p-mb-2">
        {battery && battery.value
          ? getBatteryPreview(battery)
          : getBatteryUnknownPreview}
      </div>
      <table>
        <tbody>
          {Object.entries(deviceInfoEntries).map((entry) => {
            return (
              <tr>
                <td>
                  <b>{entry[0]}</b>
                </td>
                <td className="device-info-value">{entry[1]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="device-setting-button p-mt-2">
        <Button
          className="p-button-text"
          label="Konfigurieren"
          onClick={() => {
            DeviceStore.setCurrentDevice(deviceDocId!);
            const path = NavLink.DEVICES;
            history.push(path);
            setActiveNavItem(getNavItemByPath(path));
          }}
        />
      </div>
    </Card>
  );
};

export default HomeViewDeviceCard;
