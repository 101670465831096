import React, { useState } from "react";
import DeviceStore from "../../../store/DeviceStore";
import { observer } from "mobx-react";
import { ListBox } from "primereact/listbox";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import AddDeviceModal from "./AddDeviceModal";
import { Badge } from "primereact/badge";
import { DeviceType } from "../../../api/firebase/types/devices.types";
import DeviceSettingsModal from "../deviceDetail/DeviceSettingsModal";
import { FormControlProps } from "../../../components/validation/FormControlled";
import { screenSize, useMediaQuery } from "../../../hooks/responsiveLayout";
import classNames from "classnames";

export type DeviceListCardProps = {
  devices: DeviceType[];
  className?: string;
  useFormMethods: FormControlProps;
};

const DeviceListCard: React.FC<DeviceListCardProps> = ({
  devices,
  className,
  useFormMethods,
}) => {
  const [isAddDialogVisible, setIsAddDialogVisible] = useState(false);
  const [settingsModalSerialNumber, setSettingsModalSerialNumber] =
    useState<string>();
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const [width] = useMediaQuery();

  const deviceListTemplate = (device: DeviceType & { id: string }) => {
    return (
      <div className="p-d-flex p-jc-between">
        {device.name}
        <div className="p-d-flex p-ai-center">
          {device.deviceTestMode && (
            <Badge value="Testmodus" severity="warning" />
          )}
          {device.emergencyCall === undefined && (
            <Button
              className="list-icon-button p-button-rounded p-button-danger p-ml-2"
              icon="pi pi-exclamation-triangle"
              tooltip="Das Gerät wurde noch nicht konfiguriert!"
            />
          )}
        </div>
      </div>
    );
  };

  const addDeviceButton = (
    <Button
      key="add-button"
      icon="pi pi-plus"
      className="p-button-text"
      style={{ height: 40 }}
      label="Neues Gerät"
      onClick={() => setIsAddDialogVisible(true)}
    />
  );

  const handleDeviceChange = (deviceDocId: string) => {
    useFormMethods.reset();
    DeviceStore.setCurrentDevice(deviceDocId);
  };
  return (
    <Card
      className={classNames("devicelist p-col", {
        "p-mr-2": width >= screenSize.tablet,
      })}
    >
      {showLoader && <ProgressBar mode="indeterminate" />}
      <Toolbar left={() => <h2>Geräte</h2>} right={() => addDeviceButton} />
      <i>
        Richten Sie hier Ihren Notruf-Button ein. Sie benötigen dafür die
        15-stellige Seriennummer des Geräts. Diese finden Sie an der Seite des
        Geräts unterhalb der Kennzeichnung S/N.
      </i>
      {devices.length > 0 ? (
        <ListBox
          className={className}
          value={DeviceStore.currentDevice?.id}
          options={devices}
          optionValue="id"
          optionLabel="name"
          onChange={(e) => handleDeviceChange(e.value)}
          itemTemplate={deviceListTemplate}
        />
      ) : (
        <p>
          <i>Keine registrierten Geräte</i>
        </p>
      )}
      <AddDeviceModal
        isVisible={isAddDialogVisible}
        closeModal={() => setIsAddDialogVisible(false)}
        showLoader={setShowLoader}
      />
      <DeviceSettingsModal serialNumber={settingsModalSerialNumber} />
    </Card>
  );
};

export default observer(DeviceListCard);
