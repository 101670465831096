import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import { Calendar } from "primereact/calendar";
import classNames from "classnames";

type ControlledIconFloatInputProps = FormBehaviourProps<Date | Date[]> &
  FormControlProps & {
    disabled: boolean;
  };

export default function BirthdateFormField(
  props: ControlledIconFloatInputProps
) {
  return (
    <FormControlled {...props}>
      {({ onChange, ...renderProps }) => (
        <Calendar
          className={classNames("p-mt-2", { "p-invalid": renderProps.error })}
          showIcon
          disabled={props.disabled}
          dateFormat="dd.mm.yy"
          keepInvalid
          yearNavigator
          monthNavigator
          yearRange={"1920:" + new Date().getFullYear()}
          onChange={(e) => onChange(e.value)}
          {...renderProps}
        />
      )}
    </FormControlled>
  );
}
