import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import {
  Controller,
  RegisterOptions,
  FieldError,
  UseFormMethods,
} from "react-hook-form";

type RenderFunction<T> = (props: {
  onChange: (...event: any[]) => void;
  disabled?: boolean;
  id: string;
  value: T;
  error?: boolean;
}) => JSX.Element;

type RulesType = Exclude<
  RegisterOptions,
  "valueAsNumber" | "valueAsDate" | "setValueAs"
>;

export type FormValidationProps<T> = {
  /**
   * Fehlermeldungen bei Verletzung der definierten Validierungsregeln
   */
  errorMessages?: Record<string, string>;
  /**
   * Validierungsregeln für React-Hook-Form
   */
  rules?: RulesType;
  /**
   * Zu verwendende UI-Komponente (bspw. PrimeReact <InputText />)
   */
  children: RenderFunction<T>;
};
export type FormBehaviourProps<T> = {
  /**
   * ID für die Identifikation des Form-Value und der HTML-Elemente
   */
  id: string;
  /**
   * Wert wird bei Änderung übernommen
   */
  value?: T;
  /**
   * Feld ist editierbar / nicht editierbar
   */
  disabled?: boolean;
  /**
   * Bezeichnung des Formfelds
   */
  label?: string;
};
export type FormControlProps = UseFormMethods<Record<string, any>>;
export type FormControlledProps<T> = FormControlProps &
  FormBehaviourProps<T> &
  FormValidationProps<T>;

function getErrorMessage(
  error?: FieldError,
  errorMessages?: Record<string, string>
) {
  if (!error) return undefined;
  if (error.message) return error.message;
  if (error.type && errorMessages && errorMessages[error.type])
    return errorMessages[error.type];
  return "Eingabe ist fehlerhaft";
}

const FormControlled = <T, >(props: FormControlledProps<T>) => {
  React.useEffect(() => props.setValue(props.id, props.value), [props.value]);
  const errorMessage = getErrorMessage(
    props.errors[props.id],
    props.errorMessages
  );
  return (
    <div className={classNames("p-field", "input-line")}>
      <Controller
        name={props.id}
        control={props.control}
        defaultValue={props.value}
        rules={props.rules}
        render={(renderProps) =>
          props.children({
            id: props.id,
            value: renderProps.value,
            onChange: renderProps.onChange,
            disabled: props.disabled,
            error: errorMessage !== undefined,
          })
        }
      ></Controller>
      {errorMessage && (
        <small
          id={`${props.id}-error`}
          className="p-error p-d-block form-error"
        >
          {errorMessage}
        </small>
      )}
    </div>
  );
}

export default observer(FormControlled);