import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useContext, useState } from "react";
import { DeviceTypeCategory } from "../../../api/firebase/types/devices.types";
import TooltipButton from "../../../components/buttons/TooltipButton";
import ToastContext from "../../../components/toast/ToastContext";
import DeviceStore from "../../../store/DeviceStore";
import UserDataStore from "../../../store/UserDataStore";
import { DeviceToasts } from "../../../toastMessages/deviceToastMessages";

export type AddDeviceDialogType = {
  isVisible: boolean;
  closeModal: () => void;
  showLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddDeviceDialog: React.FC<AddDeviceDialogType> = (props) => {
  const userData = UserDataStore.currentUser;
  const [serialNumber, setSerialNumber] = useState<string | undefined>(
    undefined
  );
  const [name, setName] = useState<string | undefined>(undefined);

  const showToast = useContext(ToastContext);

  const [serialNumberInvalid, setSerialNumberInvalid] =
    useState<boolean>(false);
  const [nameInvald, setNameInvalid] = useState<boolean>(false);

  const onClose = () => {
    setSerialNumber(undefined);
    setName(undefined);
    props.closeModal();
  };

  const onSave = async () => {
    let error = false;
    if (!name) {
      error = true;
      setNameInvalid(true);
    }
    if (!serialNumber || serialNumber.length !== 15) {
      error = true;
      setSerialNumberInvalid(true);
    }
    if (error) return;
    props.showLoader(true);
    props.closeModal();
    if (userData?.userId && serialNumber) {
      const { deviceCreated, deviceCreationError } = DeviceToasts;
      const creationSucceeded = await DeviceStore.addDevice({
        name: name!,
        serialNumber: serialNumber,
        typeCategory: DeviceTypeCategory.HARDWARE_DEVICE,
      });
      showToast(creationSucceeded ? deviceCreated : deviceCreationError);
      if (creationSucceeded) {
        onClose();
      }
    } else {
      console.error("undefined userId or serialNumber");
    }
    props.showLoader(false);
  };

  const dialogFooter = () => {
    return (
      <>
        <Button label="Speichern" onClick={onSave} />
        <Button
          label="Abbrechen"
          className="p-button-secondary p-button-text"
          onClick={props.closeModal}
        />
      </>
    );
  };
  const onChangeSerialNumber = (serial: string) => {
    setSerialNumber(serial);
    setSerialNumberInvalid(false);
  };

  return (
    <Dialog
      visible={props.isVisible}
      header="Gerät hinzufügen"
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={onClose}
      style={{ width: "400px" }}
    >
      <div className="p-formgrid p-grid">
        <div className="p-field p-col-12">
          <label htmlFor="name">Ihre Bezeichnung</label>
          <InputText
            id="name"
            maxLength={32}
            value={name}
            placeholder="z.B. Notruf-Button im Eingangsbereich"
            onChange={(e) => {
              e.currentTarget.value && setNameInvalid(false);
              setName(e.currentTarget.value);
            }}
          />
          {nameInvald && (
            <small id="name" className="p-error p-d-block form-error">
              Bitte geben Sie dem zu erstellenden Gerät eine Bezeichnung
            </small>
          )}
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="serialNumber">Seriennummer</label>
          <small>{"  "}15-stellig (S/N)</small>
          <TooltipButton tooltip="Die Seriennummer finden Sie auf dem Sticker an der Seite des Geräts. Bitte geben Sie alle drei Zeilen unterhalb der Kennzeichnung S/N ein (ohne Leerzeichen oder Umbruch)" />
          <InputText
            id="serialNumber"
            value={serialNumber}
            onChange={(e) => onChangeSerialNumber(e.currentTarget.value)}
          />
          {serialNumberInvalid && (
            <small id="serialNumber" className="p-error p-d-block form-error">
              Bitte geben Sie die 15-stellige Seriennummer des Geräts ein
            </small>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default observer(AddDeviceDialog);
