import { Button } from "primereact/button";
import React from "react";

export type OnboardingToolbarProps = {
  handleStep: (activeIndex: number) => void;
  handleFinishOnboarding: () => void;
  activeIndex: number;
  stepCount: number;
  hasVerifiedPhone: boolean;
  formId?: string;
  useSubmit: boolean;
};

const OnboardingToolbar: React.FC<OnboardingToolbarProps> = ({
  activeIndex,
  stepCount,
  handleStep,
  handleFinishOnboarding,
  hasVerifiedPhone,
  formId,
  useSubmit = false,
}) => {
  const showSubmitButton = activeIndex >= stepCount - 1;
  const showNextStepButton = activeIndex < stepCount - 1;
  const showPrevStepButton = activeIndex > 0;

  const handleNextStep = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (useSubmit) return;
    e.preventDefault();
    handleStep(activeIndex + 1);
  };

  return (
    <div className="p-flex-wrap p-jc-evenly p-mt-4 onboarding-toolbar p-grid">
      {showPrevStepButton && (
        <Button
          id="btn-prev-step"
          className="p-button-secondary p-col-fixed p-mb-2"
          label="Zurück"
          onClick={() => handleStep(activeIndex - 1)}
        />
      )}
      {showSubmitButton && (
        <Button
          id="btn-finish-onboarding"
          className="p-button-primary p-col-fixed p-mb-2"
          label="Fertigstellen"
          onClick={handleFinishOnboarding}
        />
      )}
      {showNextStepButton && (
        <Button
          type={useSubmit ? "submit" : "button"}
          form={formId}
          id="btn-next-step"
          disabled={!hasVerifiedPhone}
          className="p-button-primary p-col-fixed p-mb-2"
          label="Weiter"
          onClick={handleNextStep}
        />
      )}
    </div>
  );
};

export default OnboardingToolbar;
