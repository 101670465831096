import React, { useContext, useEffect, useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import ToastContext from "../../components/toast/ToastContext";
import { resetPassword } from "../../api/firebase/auth";
import { InputText } from "primereact/inputtext";

const PasswordResetDialogButton: React.FC = () => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const showToast = useContext(ToastContext);
  const opRef = useRef<OverlayPanel>(null);

  useEffect(() => {
    if (opRef.current) opRef.current.hide();
  }, []);

  const showResetPasswordSuccessToast = () =>
    showToast({
      severity: "success",
      summary: "Email gesendet",
      detail: "Bitte prüfen Sie Ihr Postfach",
    });

  const showResetPasswordErrorToast = () =>
    showToast({
      severity: "error",
      summary: "Fehler",
      detail:
        "Email konnte nicht an die angebene Email-Adresse gesendet werden",
    });

  const showNoEmailEnteredToast = () =>
    showToast({
      severity: "error",
      summary: "Keine Email angegeben",
      detail: "Bitte geben Sie eine valide Email Adresse an",
    });

  const handleResetPassword = () => {
    if (!email) {
      showNoEmailEnteredToast();
      return;
    }
    resetPassword(
      email,
      showResetPasswordSuccessToast,
      showResetPasswordErrorToast
    );
  };

  return (
    <>
      <Button
        id="pw-reset-btn"
        className="p-button-text"
        label="Passwort vergessen?"
        onClick={(e) => opRef.current && opRef.current.toggle(e)}
      ></Button>
      <OverlayPanel ref={opRef} showCloseIcon dismissable>
        <h3>Passwort zurücksetzen</h3>
        <InputText
          placeholder="Email eingeben"
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <Button
          className="p-button-primary"
          icon="pi pi-reply"
          iconPos="right"
          onClick={handleResetPassword}
        />
      </OverlayPanel>
    </>
  );
};

export default PasswordResetDialogButton;
