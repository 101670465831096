import { Card } from "primereact/card";
import React, { useState } from "react";
import { IncidentWithDevice } from "../../api/firebase/firestoreDataTypes/incidentData.types";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import IncidentStore from "../../store/IncidentStore";
import AlarmDetailModal from "./AlarmDetailModal";
import {
  alarmActionTemplate,
  alarmActionTemplateTestIncident,
  alarmCategoryTemplate,
  alarmDateTemplate,
  alarmDeviceTemplate,
  alarmStatusTemplate,
} from "./AlarmTableTemplates";

type AlarmItemProps = {
  incident: IncidentWithDevice;
};

const AlarmItem: React.FC<AlarmItemProps> = ({ incident }) => {
  const [
    selectedIncident,
    setSelectedIncident,
  ] = useState<IncidentWithDevice>();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  const onClickDeleteButton = () => setDeleteDialogVisible(true);
  const onClickDetailButton = () => setSelectedIncident(incident);

  const actionTemplateTestIncident = alarmActionTemplateTestIncident(
    onClickDeleteButton,
    onClickDetailButton
  );
  const actionTemplate = alarmActionTemplate(onClickDetailButton);

  return (
    <Card
      className="p-col-12"
      subTitle={alarmCategoryTemplate(incident)}
      footer={
        incident.deviceTestMode ? actionTemplateTestIncident : actionTemplate
      }
    >
      <div className="p-d-flex p-flex-row p-jc-between p-mb-1">
        <div>
          <b>Datum</b>
        </div>
        <div>{alarmDateTemplate(incident)}</div>
      </div>
      <div className="p-d-flex p-flex-row p-jc-between p-py-0 p-mb-1">
        <div>
          <b>Status</b>
        </div>
        <div>{alarmStatusTemplate(incident)}</div>
      </div>
      <div className="p-d-flex p-flex-row p-jc-between p-mb-1">
        <div>
          <b>Gerät</b>
        </div>
        <div>{alarmDeviceTemplate(incident)}</div>
      </div>
      <AlarmDetailModal
        selectedIncident={selectedIncident}
        setSelectedIncident={() => setSelectedIncident(undefined)}
      />
      <ConfirmDialog
        header="Testmeldung entfernen?"
        content="Möchten Sie die Testmeldung wirklich entfernen?"
        onHide={() => setDeleteDialogVisible(false)}
        isVisible={deleteDialogVisible}
        onClickYes={() => IncidentStore.deleteIncidentById(incident?.id)}
      />
    </Card>
  );
};

export default AlarmItem;
