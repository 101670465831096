import { useLayoutEffect, useState } from "react";

export function useMediaQuery() {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screenSize;
}

type ScreenSizeType = {
  phone: number;
  tablet: number;
  laptop: number;
  desktop: number;
};

export const screenSize: ScreenSizeType = {
  phone: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1200,
};
