export enum NavLink {
  START = "/",
  USER = "/user-data",
  ALARMS = "/alarms",
  DEVICES = "/devices",
  CONTACTS = "/contacts",
  ONBOARDING = "/onboarding",
  SIGNIN = "/signin",
  SIGNUP = "/signup",
}
