import { RadioButton } from "primereact/radiobutton";
import React from "react";
import {
  EmergencyCall,
  EmergencyCallGerman,
} from "../../../../api/firebase/types/incident.types";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../../components/validation/FormControlled";

type EmergencyOptionsFormFieldProps = FormBehaviourProps<string> &
  FormControlProps & {
    emergencyOptions?: EmergencyCall[];
  };

function getEnumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

const EmergencyOptionsFormField: React.FC<EmergencyOptionsFormFieldProps> = (
  props
) => {
  // Wenn dem Nutzer keine EmergencyOptions zugewiesen wurden werden alle Options angezeigt;
  const emergencyOptions = props.emergencyOptions?.length
    ? props.emergencyOptions
    : getEnumKeys(EmergencyCall);

  return (
    <FormControlled
      {...props}
      rules={{ required: true }}
      errorMessages={{ required: "Bitte geben Sie einen Notruf-Typen an" }}
    >
      {({ onChange, ...renderProps }) => (
        <>
          {emergencyOptions.map((emergencyCallOption) => {
            return (
              <div
                key={emergencyCallOption.toString()}
                className="setting-entry"
              >
                <RadioButton
                  // className={classNames({ "p-invalid": noEmergencyCallError })}
                  value={emergencyCallOption}
                  name={EmergencyCallGerman[emergencyCallOption]}
                  inputId={emergencyCallOption}
                  onChange={(e: any) => onChange && onChange(e.value)}
                  checked={
                    renderProps.value === EmergencyCall[emergencyCallOption]
                  }
                />
                <label htmlFor={emergencyCallOption}>
                  {EmergencyCallGerman[emergencyCallOption]}
                </label>
              </div>
            );
          })}
        </>
      )}
    </FormControlled>
  );
};
export default EmergencyOptionsFormField;
