import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useContext, useEffect, useRef, useState } from "react";
import ToastContext from "../../components/toast/ToastContext";
import { UserContext } from "../../store/UserProvider";
import { EmailVerificationToasts } from "../../toastMessages/emailVerificationToastMessages";
import { AuthToasts } from "../../toastMessages/authToastMessages";
import { FirebaseError } from "firebase/app";
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateEmail,
  User,
} from "firebase/auth";
import { auth } from "../../api/firebase";

const UpdateEmailButton: React.FC<{ isEditing: boolean }> = ({ isEditing }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const user = useContext(UserContext);
  const showToast = useContext(ToastContext);

  const opRef = useRef<OverlayPanel>(null);

  const updateUserEmail = async () => {
    try {
      const user = await signIn();
      const userWithNewMail = await setNewEmail(user!);
      userWithNewMail && (await sendVerificationEmail(userWithNewMail));
    } catch (e) {
      console.log(e);
    }
    opRef.current?.hide();
  };

  const signIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        user!.email!,
        password
      );
      return userCredential.user;
    } catch (error: any) {
      if (error.code === "auth/wrong-password")
        showToast(AuthToasts.wrongPasswordError);
      else if (error.code === "auth/invalid-email")
        showToast(AuthToasts.invalidMailError);
      else if (error.code === "auth/user-not-found")
        showToast(AuthToasts.userNotFoundError);
      else if (error.code === "auth/user-disabled")
        showToast(AuthToasts.userDisabledError);
    }
  };

  const sendVerificationEmail = (user: User) => {
    sendEmailVerification(user)
      .then(() => {
        showToast(EmailVerificationToasts.verificationMailSent);
      })
      .catch((e: any) => {
        showToast(EmailVerificationToasts.sendVerificationMailError);
      });
  };

  const setNewEmail = async (user: User) => {
    await updateEmail(user, email)
      .then(() => {
        showToast(EmailVerificationToasts.verificationMailSent);
        return user;
      })
      .catch((error: FirebaseError) => {
        if (error.code === "auth/invalid-email")
          showToast(AuthToasts.invalidMailError);
        else if (error.code === "auth/email-already-in-use")
          showToast(AuthToasts.emailAlreadyInUseError);
      });
    return undefined;
  };

  useEffect(() => {
    if (opRef.current) opRef.current.hide();
  }, []);

  return (
    <div className="p-d-flex p-align-center">
      <p className="userdata-p ">Email:</p>
      <b className="p-ml-3">{user?.email}</b>
      {isEditing && (
        <Button
          type="button"
          id="update-email-btn"
          className="p-button-text"
          label="Ändern"
          onClick={(e) => opRef.current && opRef.current.toggle(e)}
        />
      )}
      <OverlayPanel ref={opRef} showCloseIcon dismissable>
        <h3>Neue Email Eingeben</h3>
        <InputText
          placeholder="Email eingeben"
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <InputText
          className="p-ml-2"
          placeholder="Passwort eingeben"
          type="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <Button
          type="button"
          className="p-button-primary p-ml-2"
          icon="pi pi-reply"
          iconPos="right"
          onClick={updateUserEmail}
        />
      </OverlayPanel>
    </div>
  );
};

export default UpdateEmailButton;
