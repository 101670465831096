import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../../components/validation/FormControlled";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps;

export default function PostCodeFormField(
  props: ControlledIconFloatInputProps
) {
  return (
    <FormControlled
      {...props}
      rules={{ required: true, pattern: /^[0-9]{5}$/ }}
      errorMessages={{
        required: "Angabe fehlt",
        pattern: "Ungültige PLZ",
      }}
    >
      {(renderProps) => <InputText {...renderProps} />}
    </FormControlled>
  );
}
