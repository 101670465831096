export const links = {
  dataPolicyMedical:
    "https://www.instant-help.de/hardware-einwilligung-med-daten/",
  legalDeviceUsage: "https://www.instant-help.de/legal-device-usage/",
  dataPolicy: "https://www.instant-help.de/webseite-datenschutz",
  termsOfUse: "https://www.instant-help.de/app-agb/",
  faq: "https://www.instant-help.de/app-faq",
};

const dev_firebase_config = {
  apiKey: "AIzaSyDtvppUpPGkAQDg0JEHKM8RYjVDWgissQs",
  authDomain: "dev-instant-help.firebaseapp.com",
  projectId: "dev-instant-help",
  storageBucket: "dev-instant-help.appspot.com",
  messagingSenderId: "253016214370",
  appId: "1:253016214370:web:a0fff6441d625596347a3b",
};
const production_firebase_config = {
  apiKey: "AIzaSyBbSAUq8dAlggM0TIhMp9LLfCa6ng5BuWQ",
  authDomain: "instanthelp-4a0f0.firebaseapp.com",
  databaseURL: "https://instanthelp-4a0f0.firebaseio.com",
  projectId: "instanthelp-4a0f0",
  storageBucket: "instanthelp-4a0f0.appspot.com",
  messagingSenderId: "927909951830",
  appId: "1:927909951830:web:5905b8b3f17a2896827ef7",
};

const dev_local = {
  useEmulator: true,
  firebase_config: dev_firebase_config,
  region: "europe-west1",
};

const dev = {
  useEmulator: false,
  firebase_config: dev_firebase_config,
  region: "europe-west1",
};
const production = {
  useEmulator: false,
  firebase_config: production_firebase_config,
  region: "europe-west3",
};

const config =
  process.env.REACT_APP_STAGE === "production"
    ? production
    : process.env.REACT_APP_STAGE === "dev"
    ? dev
    : dev_local;

export default {
  ...config,
  support_mail: "mail@instant-help.de",
};
