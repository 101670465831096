import { observer } from "mobx-react";
import React, { useContext } from "react";
import { UserDataType } from "../../api/firebase/types/userData.types";
import ToastContext from "../../components/toast/ToastContext";
import { FormControlProps } from "../../components/validation/FormControlled";
import UserDataStore from "../../store/UserDataStore";
import { UserDataToasts } from "../../toastMessages/userDataToastMessages";
import DiseaseListFormField from "./formFields/DiseaseListFormField";
import LegalDeviceUsageFormField from "./formFields/LegalDeviceUsageFormField";
import MedicalPolicyFormField from "./formFields/MedicalPolicyFormField";

export type DiseaseFormProps = {
  useFormMethods: FormControlProps;
  diseases?: string[];
  hasHearingImpairment?: boolean;
  agreedMedicalPolicy?: boolean;
  agreedLegalDeviceUsage?: boolean;
  editable?: boolean;
  formId?: string;
  onSubmit?: () => void;
  onError?: () => void;
  showLoader?: () => void;
};

const DiseaseForm: React.FC<DiseaseFormProps> = ({
  useFormMethods,
  diseases,
  agreedMedicalPolicy,
  agreedLegalDeviceUsage,
  editable = true,
  formId = "disease-form",
  onSubmit,
  onError,
  showLoader,
}) => {
  const showToast = useContext(ToastContext);

  const onSubmitDiseases = (data: any) => {
    const { tempDiseases, ...otherData } = data;

    showLoader && showLoader();
    const diseaseData: Partial<UserDataType> = {
      diseases: data.tempDiseases,
      ...otherData,
    };
    UserDataStore.updateUserDataDoc(diseaseData)
      .then(() => {
        showToast(UserDataToasts.saveDiseasesSuccess);
        onSubmit && onSubmit();
      })
      .catch(() => {
        showToast(UserDataToasts.saveDiseasesError);
        onError && onError();
      });
  };

  return (
    <form
      id={formId}
      autoComplete="off"
      onSubmit={useFormMethods.handleSubmit(onSubmitDiseases)}
    >
      <DiseaseListFormField
        value={diseases}
        id="tempDiseases"
        disabled={!editable}
        {...useFormMethods}
      />
      {editable && !agreedMedicalPolicy && (
        <MedicalPolicyFormField
          id="agreedMedicalPolicy"
          disabled={!editable}
          {...useFormMethods}
        />
      )}
      {!agreedLegalDeviceUsage && (
        <LegalDeviceUsageFormField
          id="agreedLegalDeviceUsage"
          {...useFormMethods}
        />
      )}
    </form>
  );
};

export default observer(DiseaseForm);
