import { auth } from ".";
import UserDataStore from "../../store/UserDataStore";
import { createBrowserHistory } from "history";
import { UserDataType, UserRole } from "./types/userData.types";
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { NavLink } from "../../navigation/NavLinks";
import { EmergencyCall } from "./types/incident.types";

export const signUpWithMail = (
  email: string,
  password: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      try {
        await UserDataStore.addUserDataDoc({
          userId: userCredential.user?.uid,
          role: UserRole.ADMIN,
          emergencyOptions: [EmergencyCall.CONTACT_REPORTING_ONLY],
        });
        console.log("successfully created user");
        sendEmailVerification(userCredential.user);
        onSuccess && onSuccess();
        const history = createBrowserHistory();
        history.push(NavLink.ONBOARDING);
      } catch (e) {
        console.log(e);
      }
    })
    .catch((err) => {
      console.log(err);
      onError && onError();
    });
};

export const signUpWithGoogle = async () => {
  try {
    const userCredential = await signInWithGoogle();
    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    if (!additionalUserInfo?.isNewUser) {
      createBrowserHistory().push("/");
    } else {
      const { user } = userCredential;
      const userData: Partial<UserDataType> = {
        phone: user?.phoneNumber || undefined,
        userId: user?.uid || undefined,
        role: UserRole.ADMIN,
        firstName: additionalUserInfo?.username || undefined,
        emergencyOptions: [EmergencyCall.CONTACT_REPORTING_ONLY],
      };
      await UserDataStore.addUserDataDoc(userData);
      console.log("successfully signed up");
      createBrowserHistory().push("/onboarding");
    }
  } catch (err) {
    // TODO: Error Feedback
    console.log(err);
  }
};

export const signinWithMail = (
  email: string,
  password: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  signInWithEmailAndPassword(auth, email, password).catch((err) => {
    console.log(err);
    onError && onError();
  });
};

const googleAuthProvider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  return signInWithPopup(auth, googleAuthProvider);
};

export const resetPassword = (
  email: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("sent password reset mail");
      onSuccess && onSuccess();
    })
    .catch((e) => {
      console.log(e);
      onError && onError();
    });
};

export const logout = () => {
  auth
    .signOut()
    .then(() => window.location.reload())
    .catch((err) => console.error("signout failed:", err));
};
