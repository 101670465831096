import { observer } from "mobx-react";
import React from "react";
import { Steps } from "primereact/steps";
import { Card } from "primereact/card";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";

type OnboardingStepperProps = {
  activeIndex: number;
};

const OnboardingStepper: React.FC<OnboardingStepperProps> = (props) => {
  const [width] = useMediaQuery();

  const items = [
    { label: "Telefon" },
    { label: "Stammdaten" },
    { label: "Informationen" },
    { label: "Notfallkontakte" },
    { label: "Geräte" },
  ];

  if (width < screenSize.tablet) return null;

  return (
    <Card className="stepper-large-screen p-mb-4">
      <Steps model={items} activeIndex={props.activeIndex} />
    </Card>
  );
};

export default observer(OnboardingStepper);
