import React from "react";
import { Button } from "primereact/button";

type SaveCancelFooterProps = {
  submitAction?: () => void;
  cancelAction?: () => void;
  formId?: string;
};

const SaveCancelFooter: React.FC<SaveCancelFooterProps> = ({
  submitAction,
  cancelAction,
  formId,
}) => {
  return (
    <span>
      <Button
        type="submit"
        form={formId}
        key="save-button"
        style={{ marginRight: ".25em" }}
        label="Speichern"
        onClick={submitAction}
      />
      <Button
        type="button"
        key="cancel-button"
        className="p-button-secondary p-button-text"
        label="Abbrechen"
        onClick={cancelAction}
      />
    </span>
  );
};

export default SaveCancelFooter;
