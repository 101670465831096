import { FirebaseError } from "firebase/app";
import { Unsubscribe } from "firebase/auth";
import { makeAutoObservable, runInAction } from "mobx";
import {
  addDevice,
  deleteDevice,
  subscribeDevices,
  updateDevice,
} from "../api/firebase/collections/deviceCollection";
import {
  assignDeviceToUserGroup,
  getTelekomDevices,
  isDeviceAlreadyAssigned,
  unassignDeviceFromUserGroup,
} from "../api/firebase/functionCallables/telekomCallables";
import { TelekomDataType } from "../api/firebase/telekom.types";
import { DeviceType } from "../api/firebase/types/devices.types";
import SessionStore from "./SessionStore";

class DeviceStore {
  error?: any = null;
  isPending: boolean = false;
  devices: DeviceType[] = [];
  currentDevice?: DeviceType;
  telekomData: TelekomDataType = { devices: [], alarms: [] };
  unsubscribeDevices?: Unsubscribe;

  constructor() {
    makeAutoObservable(this);
  }

  subscribeDevices() {
    const onNewSnapshot = (devices: DeviceType[]) => {
      console.log("new device snapshot");
      const updatedCurrentDevice = devices.find(
        (device) => this.currentDevice?.id === device.id
      );
      runInAction(() => {
        this.devices = devices;
        this.currentDevice = updatedCurrentDevice;
      });
    };
    const onError = (error: FirebaseError) => {
      console.error(error);
      runInAction(() => {
        this.error = error;
      });
    };
    const unsubscribe = subscribeDevices(onNewSnapshot, onError);
    runInAction(() => {
      this.unsubscribeDevices = unsubscribe;
    });
  }

  addDevice = async (device: Partial<DeviceType>) => {
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      if (SessionStore.user?.uid && device.serialNumber) {
        const deviceAlreadyAssigned = await isDeviceAlreadyAssigned(
          device.serialNumber
        );
        if (deviceAlreadyAssigned === true) {
          return false;
        }
        const deviceId = await assignDeviceToUserGroup(
          SessionStore.user.uid,
          device.serialNumber
        );
        if (deviceId) {
          const newDevice = {
            ...device,
            userId: SessionStore.user.uid,
            deviceId: deviceId,
            testWithFax: false,
          };
          const newDeviceRef = await addDevice(newDevice);
          this.currentDevice = {
            ...newDevice,
            id: newDeviceRef.id,
          } as DeviceType;
        } else {
          console.error("could not save device");
        }
        runInAction(() => {
          this.isPending = false;
        });
        return true;
      } else {
        runInAction(() => {
          this.isPending = false;
          this.error = "missing identifiers";
        });
        return false;
      }
    } catch (err) {
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
      return false;
    }
  };

  updateCurrentDevice = async (updatedDevice: Partial<DeviceType>) => {
    if (!this.currentDevice) return;
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      await updateDevice(this.currentDevice.id, updatedDevice);
      runInAction(() => {
        this.isPending = false;
      });
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.isPending = false;
        this.error = err;
      });
    }
  };

  setCurrentDevice = (deviceId: string) => {
    this.resetCurrentDevice();
    runInAction(() => {
      this.currentDevice = this.devices.find(
        (device) => device.id === deviceId
      );
    });
  };

  resetCurrentDevice = () => {
    runInAction(() => {
      this.currentDevice = undefined;
    });
  };

  deleteCurrentDevice = async () => {
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    try {
      if (SessionStore.user?.uid && this.currentDevice?.deviceId) {
        await unassignDeviceFromUserGroup(
          SessionStore.user.uid,
          this.currentDevice.deviceId
        );
        await deleteDevice(this.currentDevice.id);
        runInAction(() => {
          this.currentDevice = undefined;
          this.isPending = false;
        });
        return true;
      } else {
        console.log("deleting device failed - missing identifiers");
        console.log("userId " + SessionStore.user?.uid);
        console.log("deviceId " + this.currentDevice?.deviceId);
        runInAction(() => {
          this.isPending = false;
        });
        return false;
      }
    } catch (err) {
      runInAction(() => {
        console.error(err);
        this.isPending = false;
        this.error = err;
      });
      return false;
    }
  };

  deleteContactsFromDevices = (contactIdsToDelete: string[]) => {
    this.devices
      .filter((device) =>
        device.contactIdsToNotify?.some(
          (contactId) => contactIdsToDelete.indexOf(contactId) !== -1
        )
      )
      .forEach((device) => {
        const newContacts = device.contactIdsToNotify?.filter(
          (contactToNotify) => !contactIdsToDelete.includes(contactToNotify)
        );
        updateDevice(device.id, { contactIdsToNotify: newContacts });
      });
  };

  getTelekomDevices = () => {
    if (!SessionStore.user?.uid) {
      console.log("no userId found");
      return;
    }
    runInAction(() => {
      this.isPending = true;
      this.error = undefined;
    });
    getTelekomDevices(SessionStore.user.uid)
      .then((result) => {
        runInAction(() => {
          this.isPending = false;
          this.telekomData.devices = (result.data as any).managedObjects;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.isPending = false;
          this.error = err;
        });
      });
  };

  resetStore = () => {
    this.unsubscribeDevices && this.unsubscribeDevices();
    runInAction(() => {
      this.error = null;
      this.isPending = false;
      this.devices = [];
      this.currentDevice = undefined;
      this.telekomData = { devices: [], alarms: [] };
      this.unsubscribeDevices = undefined;
    });
  };
}

export default new DeviceStore();
