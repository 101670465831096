import { Timestamp } from "firebase/firestore";

export enum EmergencyCall {
  POLICE = "POLICE",
  EMERGENCY_FIRE = "EMERGENCY_FIRE",
  EMERGENCY_MEDICAL = "EMERGENCY_MEDICAL",
  CONTACT_REPORTING_ONLY = "CONTACT_REPORTING_ONLY",
}

export enum EmergencyCallGerman {
  POLICE = "Polizei / 110",
  EMERGENCY_FIRE = "Feuerwehr / 112",
  EMERGENCY_MEDICAL = "Notarzt / 112",
  CONTACT_REPORTING_ONLY = "Nur Notfallkontakte",
}
export enum IncidentStatus {
  /**
   * Following status are provided by IH incident.status
   */
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  CLEARED = "CLEARED",
  AUTOCLOSED = "AUTOCLOSED",
  /**
   * Following status is defined for a broken connection to IH server
   */
  IHBACKEND_CONNECTION_ERROR = "IHBACKEND_CONNECTION_ERROR", // not used in IHBackend
  /**
   * Following status are provided by IH incident.psap_reporting_status (injected thru fax reporting)
   */
  TODO = "TODO",
  IN_PROGRESS = "IN_PROGRESS",
  FAILED_TEMPORARILY = "FAILED_TEMPORARILY",
  FAILED_PERMANENTLY = "FAILED_PERMANENTLY",
  SUCCESS = "SUCCESS",
}

export enum IncidentStatusGerman {
  DRAFT = "In Übermittlung",
  OPEN = "Übermittelt",
  CLEARED = "Übermittelt",
  AUTOCLOSED = "AUTOCLOSED",
  IHBACKEND_CONNECTION_ERROR = "Fehlgeschlagen",
  CONTACT_REPORTING_ONLY = "Übermittelt",
  TODO = "In Übermittlung",
  IN_PROGRESS = "In Übermittlung",
  FAILED_TEMPORARILY = "Fehlgeschlagen",
  FAILED_PERMANENTLY = "Fehlgeschlagen",
  SUCCESS = "Übermittelt",
}

export enum IncidentCategory {
  OTHER_POLICE = "other-police",
  INJURY = "injury",
}
export enum IncidentTypeEnum {
  MEDICAL = "MEDICAL",
  OTHER = "OTHER",
}

export type IHBackendTokensType = {
  access_token: string;
  refresh_token: string;
  created_date: any;
  token_type: string;
};

export type GeoLocationType = {
  geolocation: number[];
  hdop: number;
};

/**
 * Optional parameters are added from backend after reporting
 */

export type IncidentType = {
  userId?: string;
  location: GeoLocationType;
  type: IncidentTypeEnum; // define type,
  category: IncidentCategory; //define category type
  silent_message: string;
  caller_hearing_impairment: boolean;
  caller_conditions: string;
  caller_year_of_birth: number;
  caller_name: string;
  caller_phone_number: string;
  emergency_contacts_status: {
    phone_number: string;
  }[];
  status?: IncidentStatus;
  psap_reporting_status?: string;
  active_first_aiders_amount?: number;
  created?: Timestamp;
  _id?: string; // ihBackend ID
  open_time?: string; // added by IHBackend on Connection
  emergencyCall?: EmergencyCall; //  to determine type of incident for UI independent from IHBackend
  deviceId?: string; // to lookup device attributes to incident
  deviceTestMode?: boolean; // UserBO Device Testing Mode
  invokerTaskId: string; // unique identifier for desktop alarm reporting procedure (used for filtering -> avoids polling)
};
