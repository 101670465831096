import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import DeviceStore from "../../store/DeviceStore";
import IncidentStore from "../../store/IncidentStore";
import UserDataStore from "../../store/UserDataStore";
import HomeViewDeviceCard from "./HomeViewDeviceCard";
import HomeViewInfoCard from "./HomeViewInfoCard";

const HomeView: React.FC = () => {
  const userData = UserDataStore.currentUser;
  const deviceDocs = DeviceStore.devices;
  const incidents = IncidentStore.incidents;
  const realIncidents = incidents.filter(
    (incident) => incident.deviceTestMode !== true
  );

  const [width] = useMediaQuery();

  return (
    <div
      className={classNames("homeview-container", "p-grid", {
        "p-jc-center": width < screenSize.tablet,
        "p-px-3": width >= screenSize.tablet,
      })}
    >
      <HomeViewInfoCard incidents={realIncidents} userData={userData} />
      {deviceDocs.map((device) => {
        return <HomeViewDeviceCard device={device} deviceDocId={device.id} />;
      })}
    </div>
  );
};
export default observer(HomeView);
