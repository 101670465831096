import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import ButtonInput from "../../../components/input/ButtonInput";
import { ListBox } from "primereact/listbox";
import deleteButtonListTemplate from "../../../components/templates/DeleteButtonListTemplate";

type ControlledIconFloatInputProps = FormBehaviourProps<string[]> &
  FormControlProps & {
    disabled: boolean;
  };

export default function DiseaseListFormField(
  props: ControlledIconFloatInputProps
) {
  const onDeleteDisease = (diseaseToRemove: string) => {
    const tempDiseases: string[] | undefined = props.getValues("tempDiseases");
    if (!tempDiseases) return;
    const newTempDiseases: string[] | undefined = tempDiseases.filter(
      (disease: string) => disease !== diseaseToRemove
    );
    props.setValue("tempDiseases", newTempDiseases);
  };

  const onAddDisease = (disease?: string) => {
    if (!disease || disease.length === 0) return;
    const tempDiseases: string[] | undefined = props.getValues("tempDiseases");

    if (tempDiseases === undefined) {
      props.setValue("tempDiseases", [disease]);
    } else {
      props.setValue("tempDiseases", [...tempDiseases, disease]);
    }
  };

  const listTemplate = deleteButtonListTemplate(
    onDeleteDisease,
    !props.disabled
  );

  return (
    <FormControlled {...props}>
      {({ onChange, ...renderProps }) => {
        return (
          <>
            {!props.disabled && (
              <ButtonInput
                onClick={(diseaseInputText) => onAddDisease(diseaseInputText)}
                placeholder="Vorerkrankungen, Anfahrtsbeschreibung o.Ä."
                buttonIcon="pi-plus"
              />
            )}
            {renderProps.value && renderProps.value.length > 0 ? (
              <ListBox
                options={renderProps.value}
                disabled={props.disabled}
                itemTemplate={listTemplate}
                {...renderProps}
              />
            ) : (
              <></>
            )}
          </>
        );
      }}
    </FormControlled>
  );
}
