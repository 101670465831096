import classNames from "classnames";
import { observer } from "mobx-react";
import { Card } from "primereact/card";
import React from "react";
import { IncidentWithDevice } from "../../api/firebase/firestoreDataTypes/incidentData.types";
import { Container } from "../../components/container";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import DeviceStore from "../../store/DeviceStore";
import IncidentStore from "../../store/IncidentStore";
import AlarmItemView from "./AlarmItemView";
import AlarmTableView from "./AlarmTableView";

const AlarmView: React.FC = () => {
  const incidents = IncidentStore.incidents;
  const devices = DeviceStore.devices;

  const [width] = useMediaQuery();

  const incidentsWithDevice = incidents?.map((incident) => {
    const deviceOfIncident = devices.find(
      (device) => device.deviceId === incident.deviceId
    );
    return {
      ...incident,
      device: deviceOfIncident,
    } as IncidentWithDevice;
  });

  const filterIncidents = (getTestAlerts: boolean = false) => {
    return incidentsWithDevice?.filter((incident) => {
      return getTestAlerts
        ? incident.deviceTestMode === getTestAlerts
        : !incident.deviceTestMode;
    });
  };

  const realIncidents = filterIncidents();
  const testIncidents = filterIncidents(true);

  return (
    <Container
      containerClassName={classNames("alarmview-container", "p-grid", {
        "p-px-3": width >= screenSize.tablet,
      })}
    >
      {width >= screenSize.tablet ? (
        <Card className="alarm-tableview p-col">
          <AlarmTableView
            realIncidents={realIncidents}
            testIncidents={testIncidents}
          />
        </Card>
      ) : (
        <AlarmItemView
          realIncidents={realIncidents}
          testIncidents={testIncidents}
        />
      )}
    </Container>
  );
};
export default observer(AlarmView);
