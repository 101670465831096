import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useForm } from "react-hook-form";
import { screenSize, useMediaQuery } from "../../hooks/responsiveLayout";
import DeviceStore from "../../store/DeviceStore";
import DeviceSettingsCard from "./deviceDetail/DeviceSettings";
import DeviceSettingsModal from "./deviceDetail/DeviceSettingsModal";
import DeviceListCard from "./devicesListCard/DeviceListCard";

const DeviceView: React.FC<{ isOnboarding?: boolean }> = ({ isOnboarding }) => {
  const devices = DeviceStore.devices;
  const currentDevice = DeviceStore.currentDevice;
  const useFormMethods = useForm();
  const [width] = useMediaQuery();

  const renderDeviceSettings = () => {
    if (!currentDevice) return;
    if (width > screenSize.desktop && currentDevice.emergencyCall) {
      return (
        <DeviceSettingsCard
          useFormMethods={useFormMethods}
          currentDevice={currentDevice}
        />
      );
    } else
      return (
        <DeviceSettingsModal
          serialNumber={currentDevice?.serialNumber}
          device={currentDevice}
        />
      );
  };

  return (
    <div className="deviceview-container p-grid">
      <div
        className={classNames("deviceview-content", {
          "p-jc-center": width < screenSize.tablet || isOnboarding,
          "p-px-3": width >= screenSize.tablet,
        })}
      >
        <DeviceListCard useFormMethods={useFormMethods} devices={devices} />
        {(!isOnboarding || (isOnboarding && currentDevice)) && (
          <div className="device-detail">{renderDeviceSettings()}</div>
        )}
      </div>
    </div>
  );
};

export default observer(DeviceView);
