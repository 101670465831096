import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import { Checkbox } from "primereact/checkbox";
import { links } from "../../../config";
import classNames from "classnames";
import { Divider } from "primereact/divider";

type ControlledIconFloatInputProps = FormBehaviourProps<boolean> &
  FormControlProps;

export default function MedicalPolicyFormField(
  props: ControlledIconFloatInputProps
) {
  const {
    tempDiseases,
    hasHearingImpairment,
  }: {
    tempDiseases: Array<string>;
    hasHearingImpairment: boolean;
  } = props.watch(["tempDiseases", "hasHearingImpairment"]);
  const hasDiseases = tempDiseases && tempDiseases.length > 0;
  return (
    <FormControlled
      {...props}
      rules={{
        validate: {
          compliance: (value) => {
            return (!hasDiseases && !hasHearingImpairment) || value === true;
          },
        },
      }}
      errorMessages={{
        compliance: "Bitte stimmen Sie der Verarbeitung medizinischer Daten zu",
      }}
    >
      {({ value, onChange, ...renderProps }) => (
        <div className="p-m-3">
          <Divider />
          <Checkbox
            className={classNames("p-mt-3", {
              "p-invalid": renderProps.error,
            })}
            checked={value}
            onChange={(e) => onChange(e.checked)}
            {...renderProps}
          />
          <label className="p-ml-2">
            Hiermit stimme ich der{" "}
            <a href={links.dataPolicyMedical} target="_blank">
              Verarbeitung meiner Notfalldaten
            </a>{" "}
            zu.
          </label>
        </div>
      )}
    </FormControlled>
  );
}
