import { NavLink } from "./NavLinks";

export type NavItem = {
  label: string;
  icon: string;
  path: NavLink;
};

export const navItems: NavItem[] = [
  {
    label: "Start",
    icon: "pi pi-fw pi-home",
    path: NavLink.START,
  },
  {
    label: "Profil",
    icon: "pi pi-fw pi-user",
    path: NavLink.USER,
  },
  {
    label: "Meldungen",
    icon: "pi pi-fw pi-exclamation-triangle",
    path: NavLink.ALARMS,
  },
  {
    label: "Geräte",
    icon: "pi pi-fw pi-share-alt",
    path: NavLink.DEVICES,
  },
  {
    label: "Notfallkontakte",
    icon: "pi pi-fw pi-users",
    path: NavLink.CONTACTS,
  },
];

export const getNavItemByPath = (path: NavLink) => {
  const item = navItems.find((item) => item.path === path);
  if (!item) return navItems[0];
  return item;
};
