import { InputText } from "primereact/inputtext";
import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../../components/validation/FormControlled";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps;

export default function AddressFormField(props: ControlledIconFloatInputProps) {
  return (
    <FormControlled
      {...props}
      rules={{ required: true, minLength: 3 }}
      errorMessages={{
        required: "Angabe fehlt",
        minLength: "Angabe ungültig",
      }}
    >
      {(renderProps) => <InputText {...renderProps} />}
    </FormControlled>
  );
}
