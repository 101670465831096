import React from "react";
import classnames from "classnames";

export type ContainerProps = {
  center?: boolean;
  flexDirection?: "row" | "column";
  containerClassName: string;
};

export const Container: React.FC<ContainerProps> = ({
  children,
  center = true,
  flexDirection = "row",
  containerClassName,
}) => {
  const classes = classnames({ center }, flexDirection, containerClassName);

  return <div className={classes}>{children}</div>;
};
