import { InputText } from "primereact/inputtext";
import React from "react";
import { mailRegEx } from "../../../api/validation";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps;

export default function MailFormField(props: ControlledIconFloatInputProps) {
  return (
    <FormControlled
      {...props}
      rules={{ pattern: mailRegEx }}
      errorMessages={{
        pattern: "E-Mail ungültig",
      }}
    >
      {(renderProps) => (
        <>
          <InputText {...renderProps} placeholder="Optional" />
        </>
      )}
    </FormControlled>
  );
}
