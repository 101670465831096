import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../../components/validation/FormControlled";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps;

export default function SilentMessageFormField(
  props: ControlledIconFloatInputProps
) {
  return (
    <FormControlled {...props}>
      {(renderProps) => (
        <InputTextarea
          className="p-my-2"
          placeholder="z.B. Der Betrieb ABC wird überfallen. Bitte nutzen Sie den Seiteneingang."
          rows={5}
          cols={50}
          autoResize
          {...renderProps}
        />
      )}
    </FormControlled>
  );
}
