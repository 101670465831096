import React, { useContext, useState } from "react";
import { Container } from "../../components/container";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import Card from "../../components/card";
import { observer } from "mobx-react";
import { signUpWithGoogle, signUpWithMail } from "../../api/firebase/auth";
import PasswordResetDialogButton from "../login/PasswordResetDialogButton";
import ToastContext from "../../components/toast/ToastContext";
import { useForm } from "react-hook-form";
import { Divider } from "primereact/divider";
import ToastMessages from "../../toastMessages/signupToastMessages";
import EmailFormField from "./formFields/EmailFormField";
import PasswordFormField from "./formFields/PasswordFormField";
import PasswordRepeatFormField from "./formFields/PasswordRepeatFormField";
import AgreeTermsFormField from "./formFields/AgreeTermsFormField";

const SignupView: React.FC = () => {
  const [googleComplianceError, setGoogleComplianceError] = useState(false);

  const showToast = useContext(ToastContext);
  const useFormMethods = useForm({
    defaultValues: {
      email: undefined,
      password: undefined,
      passwordRepeat: undefined,
      agreedTerms: false,
    },
  });

  const onSubmit = async (data: any) => {
    const { email, password } = data;
    signUpWithMail(email, password, undefined, () =>
      showToast(ToastMessages.mailAlreadyUsedError)
    );
  };

  const onSubmitGoogle = async () => {
    if (!useFormMethods.getValues("agreedTerms")) {
      setGoogleComplianceError(true);
      showToast(ToastMessages.missingComplianceError);
      return;
    }
    signUpWithGoogle();
  };

  const footer = () => {
    return (
      <p>
        Sie haben bereits einen Account? <Link to="signin">Hier anmelden</Link>
        <br />
        <PasswordResetDialogButton />
      </p>
    );
  };

  return (
    <Container containerClassName="signup-container p-grid">
      <Card
        className="signup-card p-col"
        title="Registrieren"
        footer={footer()}
      >
        <form onSubmit={useFormMethods.handleSubmit(onSubmit)}>
          <EmailFormField id="email" {...useFormMethods} />
          <PasswordFormField id="password" {...useFormMethods} />
          <PasswordRepeatFormField id="password-repeat" {...useFormMethods} />

          <Divider className="p-my-4" />
          <AgreeTermsFormField
            googleComplianceError={googleComplianceError}
            setGoogleComplianceError={setGoogleComplianceError}
            id="agreedTerms"
            {...useFormMethods}
          />
          <Divider className="p-my-4" />

          <div className="p-d-flex p-flex-wrap p-jc-evenly p-ai-center">
            <Button
              id="signup-button"
              type="submit"
              className="signup-button p-button-primary p-mb-2"
              label="Konto erstellen"
              onClick={() => useFormMethods.handleSubmit(onSubmit)}
            />

            <Button
              type="button"
              className="p-button-primary p-button-text p-mb-2"
              label="Mit Google Anmelden"
              icon="pi pi-google"
              iconPos="left"
              onClick={onSubmitGoogle}
            />
          </div>
        </form>
      </Card>
    </Container>
  );
};

export default observer(SignupView);
