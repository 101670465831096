import React, { useContext } from "react";
import { Button } from "primereact/button";
import ToastContext from "../../components/toast/ToastContext";
import { EmailVerificationToasts } from "../../toastMessages/emailVerificationToastMessages";
import { sendEmailVerification, User } from "firebase/auth";
import { observer } from "mobx-react";

const EmailVerificationIncomplete: React.FC<{ user: User | null }> = ({
  user,
}) => {
  const showToast = useContext(ToastContext);

  // const refresh = () => {
  //   window.location.reload();
  // };

  const handleResendMail = () => {
    sendEmailVerification(user!)
      .then(() => {
        showToast(EmailVerificationToasts.verificationMailSent);
      })
      .catch((e: any) => {
        showToast(EmailVerificationToasts.sendVerificationMailError);
      });
  };

  // useEffect(() => {
  //   const refreshInterval = setInterval(refresh, 5000);
  //   return () => clearInterval(refreshInterval);
  // }, []);

  return (
    <div className="email-verification-incomplete-container p-mx-3 p-d-flex p-flex-column p-ai-center">
      <h2>Bitte bestätigen Sie Ihre Email-Adresse</h2>
      <p>
        Sie haben eine Email von uns erhalten. Klicken Sie auf den Link in der
        Email, um Ihre Email-Adresse zu bestätigen.
      </p>
      <p>
        Wenn Sie Ihre Email-Adresse bereits verifiziert haben, laden Sie die
        Seite neu, um mit der Einrichtung fortzufahren.
      </p>
      <p>
        Wenn Sie keine Email erhalten haben oder die Verifizierungsmail nicht
        mehr gültig ist, klicken Sie auf "Mail erneut senden", um eine neue
        Verifizierungsmail zu erhalten.
      </p>
      <div className="button-bar p-mt-4 p-d-flex p-jc-evenly p-flex-wrap">
        <Button
          className="p-button-outlined p-mb-2"
          label="Mail erneut senden"
          onClick={handleResendMail}
        />
        <Button
          label="Seite neu laden"
          className="reload-button p-button-outlined p-mb-2"
          onClick={() => window.location.reload()}
        />
      </div>
    </div>
  );
};

export default observer(EmailVerificationIncomplete);
