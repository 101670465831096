import React from "react";
import FormControlled, {
  FormBehaviourProps,
  FormControlProps,
} from "../../../components/validation/FormControlled";
import IconFloatInput from "../../../components/input/IconFloatInput";

type ControlledIconFloatInputProps = FormBehaviourProps<string> &
  FormControlProps;

export default function PasswordFormField(
  props: ControlledIconFloatInputProps
) {
  return (
    <FormControlled
      {...props}
      rules={{
        minLength: 8,
        required: true,
      }}
      errorMessages={{
        minLength: "Das Passwort muss mindestens 8 Zeichen lang sein",
        required: "Bitte geben Sie ein achtstelliges Passwort an",
      }}
    >
      {(renderProps) => (
        <IconFloatInput
          type="password"
          label="Passwort"
          icon="pi-lock"
          {...renderProps}
        />
      )}
    </FormControlled>
  );
}
