import React, { createContext, useRef } from "react";
import { Toast, ToastMessage } from "primereact/toast";

export type ToastMessageType = ToastMessage | ToastMessage[];

type ToastContextType = (toasts: ToastMessageType) => void;

const ToastContext = createContext<ToastContextType>(() => {});

export const ToastProvider: React.FC = (props) => {
  const toastRef = useRef<Toast>(null);

  const showToast = (toasts: ToastMessageType) => {
    toastRef.current
      ? toastRef.current.show(toasts)
      : console.error("Toast unavailable");
  };

  return (
    <ToastContext.Provider value={showToast}>
      <Toast ref={toastRef} />
      {props.children}
    </ToastContext.Provider>
  );
};

export default ToastContext;
