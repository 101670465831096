import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import moment from "moment";
import { functions } from "..";
import { DeviceBatteryType } from "../types/devices.types";

export const createTelekomUser = async (
  uuid: string,
  firstName: string,
  lastName: string
): Promise<boolean> => {
  console.log("creating user ..");
  const createUserFunction = httpsCallable(
    functions,
    "telekom-createTelekomUser"
  );
  try {
    const status = await createUserFunction({
      firstName: firstName,
      lastName: lastName,
      uuid: uuid,
    });
    return status.data === 201;
  } catch (err) {
    return false;
  }
};

export const isDeviceAlreadyAssigned = async (
  serialNumber: string
): Promise<boolean> => {
  console.log("is device already assigned ..");
  const isAssignedFunction = httpsCallable<{ serialNumber: string }, boolean>(
    functions,
    "telekom-isDeviceAlreadyAssigned"
  );
  try {
    const ret = await isAssignedFunction({ serialNumber });
    console.log("device assignment " + ret.data);
    return ret.data;
  } catch (err) {
    console.error("failed to check if device is already assigned");
    return true;
  }
};

export const assignDeviceToUserGroup = async (
  userId: string,
  serialNumber: string
): Promise<string> => {
  console.log("assigning device ..");
  const assignFunction = httpsCallable<
    { userId: string; serialNumber: string },
    string
  >(functions, "telekom-assignDeviceToGroup");
  try {
    const deviceId = await assignFunction({ userId, serialNumber });
    if (deviceId.data) {
      console.log("assigned device to group");
      return Promise.resolve(deviceId.data);
    } else {
      console.error("failed assigning status");
      return Promise.reject();
    }
  } catch (err) {
    console.error("failed to assign device to group");
    return Promise.reject();
  }
};
export const unassignDeviceFromUserGroup = async (
  userId: string,
  deviceId: string
): Promise<boolean> => {
  console.log("unassigning device ..");
  const unassignFunction = httpsCallable(
    functions,
    "telekom-unassignDeviceFromGroup"
  );
  try {
    const status = await unassignFunction({ userId, deviceId });
    if (status.data === 204) {
      console.log("unassigned device ..");
      return Promise.resolve(true);
    }
    console.error("failed to unassign device from group");
    return Promise.reject(false);
  } catch (err) {
    console.error("failed to unassign device from group");
    return Promise.reject(false);
  }
};

export const getTelekomDevices = (
  uuid: string
): Promise<HttpsCallableResult> => {
  console.log("getting devices ..");
  const getDevicesFunction = httpsCallable<{ uuid: string }, any>(
    functions,
    "telekom-getDevices"
  );
  return getDevicesFunction({
    uuid: uuid,
  });
};

export const isUserRegisteredTelekom = async (
  uuid: string
): Promise<boolean> => {
  const isUserAvailableFunction = httpsCallable(
    functions,
    "telekom-isUserAvailable"
  );
  try {
    const status = await isUserAvailableFunction({
      uuid: uuid,
    });
    return status.data === 200;
  } catch (err) {
    return false;
  }
};
export const getBatteryByDeviceId = async (
  deviceId: string
): Promise<DeviceBatteryType | undefined> => {
  const getBatteryFunction = httpsCallable<{ deviceId: string }, any>(
    functions,
    "telekom-getBattery"
  );
  try {
    const ret = await getBatteryFunction({
      deviceId,
    });
    if (ret.data.series && ret.data.series.length == 1 && ret.data.values) {
      const batteryMeasurement = ret.data.values;
      if (batteryMeasurement) {
        const length = Object.keys(batteryMeasurement).length;
        const latestKey = Object.keys(batteryMeasurement)[length - 1];
        const latestValue: any = Object.values(batteryMeasurement)[length - 1];
        return { value: latestValue[0].min, date: moment(latestKey) };
      }
    }
    return undefined;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};
