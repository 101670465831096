import { InputText } from "primereact/inputtext";
import React from "react";
import classnames from "classnames";
import classNames from "classnames";

export type IconFloatInputProps = {
  icon: string;
  id: string;
  label: string;
  value?: string;
  type?: string;
  className?: string;
  keyfilter?: string;
  error?: boolean;
  onChange?: (text: string) => void;
  onKeyDown?: (event: any) => void;
};

const IconFloatInput: React.FC<IconFloatInputProps> = (props) => {
  return (
    <div
      className={classnames(
        "p-inputgroup",
        "p-field",
        "p-mt-4",
        props.className
      )}
    >
      <span className="p-inputgroup-addon">
        <i className={`pi ${props.icon}`}></i>
      </span>
      <span className="p-float-label">
        <InputText
          className={classNames({ "p-invalid": props.error })}
          id={props.id}
          type={props.type}
          value={props.value}
          onKeyDown={props.onKeyDown}
          onChange={(e: any) =>
            props.onChange && props.onChange(e.target.value)
          }
          keyfilter={props.keyfilter}
        />
        <label htmlFor={props.id}>{props.label}</label>
      </span>
    </div>
  );
};

export default IconFloatInput;
