import { ToastMessageType } from "../components/toast/ToastContext";

export const UserDataToasts: { [key: string]: ToastMessageType } = {
  saveUserDataSuccess: {
    severity: "success",
    summary: "Stammdaten Gespeichert",
    detail: "Stammdaten erfolgreich gespeichert",
  },
  saveUserDataError: {
    severity: "error",
    summary: "Speichern fehlgeschlagen",
    detail: "Die Stammdaten konnten nicht gespeichert werden",
  },
  saveDiseasesSuccess: {
    severity: "success",
    summary: "Informationen gespeichert",
    detail: "Die Informationen wurden erfolgreich gespeichert",
  },
  saveDiseasesError: {
    severity: "error",
    summary: "Speichern fehlgeschlagen",
    detail: "Die Informationen konnten nicht gespeichert werden",
  },
  missingMedicalAgreementError: {
    severity: "error",
    summary: "Fehlende Zustimmung",
    detail:
      "Bitte stimme den Bestimmungen zur Verarbeitung von medizinischen Daten zu",
  },
};
