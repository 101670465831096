import { ToastMessageType } from "../components/toast/ToastContext";

export const AuthToasts: { [key: string]: ToastMessageType } = {
  invalidMailError: {
    severity: "error",
    summary: "Email ungültig",
    detail: "Die angegebene Email Adresse ist nicht valide",
  },
  emailAlreadyInUseError: {
    severity: "error",
    summary: "Email wird bereits verwendet",
    detail:
      "Die angegebene Email Adresse wird bereits von einem anderen Nutzer verwendet",
  },
  wrongPasswordError: {
    severity: "error",
    summary: "Falsches Passwort",
    detail: "Das angegebene Passwort ist nicht korrekt",
  },
  userNotFoundError: {
    severity: "error",
    summary: "Nutzer nicht gefunden",
    detail:
      "Es wurde kein Nutzer mit den angegebenen Nutzerinformationen gefunden",
  },
  userDisabledError: {
    severity: "error",
    summary: "Nutzer deaktiviert",
    detail: "Der angegebene Nutzer wurde deaktiviert",
  },
  weakPasswordError: {
    severity: "error",
    summary: "Passwort zu schwach",
    detail: "Das neue Passwort ist zu schwach und wurde deshalb nicht geändert",
  },
  passwordUpdateSuccess: {
    severity: "success",
    summary: "Passwort erfolgreich geändert",
    detail: "Das Passwort wurde erfolgreich geändert",
  },
};
